import React, { useContext } from 'react';
import { Language } from '../../infrastructure/Languages';

export const VerifyUserAccountDisabled = () => {

    const language = useContext(Language);

    return (
        <div>
            <h1>{"Account verification"}</h1>
            <p>{"Feature Coming Soon"}</p>
        </div>
        )
}