import { Translation } from '../core/Translation';

export const CapabilityName = {
    BackSplash: "backsplash",
    Loader: "loader",
    Modal: "modal",
    Notification: "notification",
    ReleaseNotes: "releasenotes",
};

export const ProductGroup = {
    SkirtSealPouch: 1,
    PlowBottomPouch: 2,
    TwoSideSealPouch: 3,
    ThreeSideSealPouch: 4,
    Doyen: 5,
    PressProof: 6,
    RollStock: 7,
    QuadSealPouch: 29,
    FinSealPouch: 30,
    FlatBottomPouch: 47,
};

export const FinishType = {
    Matte: 1,
    Gloss: 2,
    SoftTouchMatte: 3,
};

export const HangHole = {
    None: 1,
    Sombrero_30_mm: 2,
    Round_6_4_mm: 3,
    Round_5_mm: 4,
    Round_6_mm: 5,
    Round_8_mm: 6,
    Round_10_mm: 7,
    Sombrero_25_mm: 8,
    Sombrero_20_mm: 9,
};

export const RoundedCorners = {
    None: 1,
    SixMillimeters: 2,
};

export const SealWidth = {
    None: 1,
    Seal_5_mm: 2,
    Seal_8_mm: 3,
    Seal_10_mm: 4,
};

export const TearNotch = {
    None: 1,
    Default: 2,
};

export const Vent = {
    None: 1,
    Valve: 2,
    VentHole: 3,
};

export const Zipper = {
    None: 1,
    PowderProof: 2,
    PTC: 3,
    ChildResistant: 4,
    Recyclable: 5,
    Compostable: 6,
    Compostable_CR: 7,
    CleanTear: 8,
};

export const SalesChannel = {
    Estimator: 1,
    Website: 2,
};

export const SalesState = {
    Undefined: 0,
    Initial: 10,
    OfferCreated: 20,
    OfferReviewPending: 30,
    OfferReviewApproved: 40,
    OfferSent: 50,
    OrderSubmitted: 60,
    OrderPendingArtwork: 70,
    OrderCreated: 100,
    OfferReviewRejected: 110,
    OfferExpired: 120,
    OfferReadyForProduction: 130
}

export const MeasureUnit = {
    Undefined: 0,
    Composite: 1,
    LengthInCentimeters: 11,
    LengthInFeet: 12,
    LengthInInches: 13,
    LengthInMeters: 14,
    LengthInMillimeters: 15,
    LengthInMiles: 16,
    AreaInMsi: 21,
    AreaInSquareCentimeters: 22,
    AreaInSquareFeet: 23,
    AreaInSquareInches: 24,
    AreaInSquareMeters: 25,
    AreaInSquareMillimeters: 26,
    AreaInSquareMiles: 27,
    WeightInGrams: 31,
    WeightInKilograms: 32,
    WeightInNewtons: 33,
    WeightInOunces: 34,
    WeightInPounds: 35,
    TimeInHours: 41,
    TimeInMinutes: 42,
    TimeInSeconds: 43,
    PressureInBar: 51,
    PressureInMillibar: 52,
    PressureInPsi: 53,
    UnitsInPieces: 61,
    MoneyInUSD: 71,
};

export const ComponentType = {
    Undefined: 0,
    ThreeSideSeal: 1,
    Rollstock: 2,
    StandupPouch: 3,
    SideGussetPouch: 4,
    TestRoll: 5
}

export const PaymentTerms = {
    Prepaid: 'Prepaid',
    Prepaid_50_50: 'Prepaid 50/50',
    Net30: 'Net 30 Days',
    Net30_EOM: 'Net 30 Days EOM',
    Net30_50_50: 'Net 30 Days 50/50',
    Net45: 'Net 45 Days',
    Net60: 'Net 60 Days',
    Net90: 'Net 90 Days',
    Net90_EOM: 'Net 90 Days EOM'
}


export const ApplicationFeatures = {
    None: 0,
    ThirdPartyCheckoutEnabled: 1,
    AutoOrderSubmissionToNavEnabled: 2,
    OrderPlacementApplicationEnabled: 4
}

export const OrderType = {
    Other: 'Other',
    TestRoll: 'Test Roll',
    PressProof: 'Press Proof',
}

export const CountriesWithSubSelection = [
    {
        country: "United States",
        selectionLabel: "State"
    },
    {
        country: "Canada",
        selectionLabel: "Province"
    }
]

export const ReorderDeniedReasons = {
    NoSkuFound: 0,
    ConvertingMethodNotAllowed: 1,
    HasOutsourcingCost: 2
}

// do not change this sorting
export const MyAccountValidationQuestions = [
    <Translation id='b23b34d6-ec87-459e-a812-663f8beed555' defaultMessage='Quote' category='Label' />,
    <Translation id='353a71ad-72a4-4566-8fc6-b06024ad110d' defaultMessage='Order' category='Label' />
]