import React, { Component, useState, useRef, useContext, useEffect } from 'react';
import { ShoppingBreadcrumbs, OrderReference } from '../../core/Breadcrumbs';
import { ApiDataAccess } from '../../infrastructure/ApiDataAccess';
import { Translation } from '../../core/Translation';
import { Card } from '../../core/Card';
import { ExpandableCard } from '../../components/offer/ExpandableCard';
import { PaymentComponent } from '../../components/offer/PaymentComponent';
import { useForm, get } from "react-hook-form";
import { ReasonCode, PaymentMethod, ResponseStatus } from '../../core/offer/Constants';
import { Alert } from '../../components/offer/Alert';
import { FormatProvider } from '../../core/offer/FormatProvider';
import { Popup } from '../../core/Popup';
import { CapabilityName, SalesChannel, CountriesWithSubSelection } from '../../infrastructure/Constants';
import { PaymentTerms } from '../../core/offer/Constants';
import { HookEditAddress } from '../../components/offer/HookEditAddress';
import { useGlobalOfferContext, useGlobalOfferId, useLoadGlobalOfferId, useOrderPlacementRouter } from '../../infrastructure/GlobalOfferId';
import OrderPlacementApi from '../../infrastructure/OrderPlacementApi';

import { useCookies } from 'react-cookie';

import './PaymentPage.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { CapabilitiesContext, SettingsContext } from '../../infrastructure/Contexts';

export const ProformaInvoiceLink = ({ globalOfferId, billingAddress }) => {

    const [bytes, setBytes] = useState(null);

    useEffect(() => {

        const init = async () => {
            if (globalOfferId && billingAddress) {

                var dataAccess = new ApiDataAccess('api/document');
                var request = {
                    billingAddress: billingAddress
                };

                var b = await dataAccess.post(`/${globalOfferId}/proformainvoice`, request);
                setBytes(b);
            }
        }

        init();

    }, [])

    const onDownloadClick = () => {
        const elem = document.getElementById("btnProformaInvoiceDownload");
        elem.click();
    }


    return (
        <>
            {bytes &&
                <>
                <button className='btn btn-tertiary-outline pull-right' onClick={() => { onDownloadClick() }}>Proforma Invoice</button>
                <a id='btnProformaInvoiceDownload' style={{ visibility: "hidden" }} href={"data:text/plain;base64," + bytes} download='invoice.pdf'>Download</a>
                </>
            }
        </>
        )
}


export const PaymentPage = ({ offer, orderReference, orderIntent, status, analyticsMetadata, initialized }) => {

    const globalOfferId = useGlobalOfferId();
    const { offerContext, loadOfferContextStatus } = useGlobalOfferContext(globalOfferId.globalOfferId);
    const orderPlacementApi = new OrderPlacementApi("/");
    const appSettings = useContext(SettingsContext);
    const location = useLocation();
    const fromWeb = location?.state;
    const { trackEvent } = useTracking({ page: "Payment Page" });

    const {
        register,
        handleSubmit,
        trigger,
        unregister,
        watch,
        reset,
        setValue,
        control,
        setError,
        getValues,
        formState: { errors, isSubmitting, isValid },
    } = useForm({
        mode: "onBlur",
        shouldFocusError: true,
        defaultValues: {
            billingAddress: {
                firstName: "",
                lastName: "",
                city: "",
                state: "",
                postalCode: "",
                country: ""
            }
        }
    });

    const completePopup = useRef(null);
    const [culture, setCulture] = useState('en-US');
    const [totalAmount, setTotalAmount] = useState(-1);
    const [currency, setCurrency] = useState(null);
    const [orderNumber, setOrderNumber] = useState(null);

    const [expandedIndex, setExpandedIndex] = useState(0);
    const [paymentOptions, setPaymentOptions] = useState(null);
    const [paymentOption, setPaymentOption] = useState(null);
    const [reason, setReason] = useState(null);
    const capabilitiesContext = useContext(CapabilitiesContext);

    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);

    const [loaded, setLoaded] = useState(false);
    const [countries, setCountryListState] = useState([]);

    const [cookies, setCookie] = useCookies(['visitorId']);
    const [visitorId, setVisitorId] = useState(null);
    const [session, setSession] = useState(null);

    const [billingAddress, setBillingAddress] = useState(null);

    const [submittingPayment, setSubmittingPayment] = useState(false);

    /*Clean up the lingering modal until it can be slightly refactored to unload correctly */
    useEffect(() => {
        return () => {
            console.log("hide loader, unmounted");
            loader.hide();
        };
    }, []);

    useEffect(() => {
        if (globalOfferId.globalOfferId) {
            loadSession(globalOfferId.globalOfferId);
        }
    }, [globalOfferId]);

    useEffect(() => {

        var id = cookies.visitorId;
        if (id) {
            setVisitorId(id);
        }

        if (submittingPayment) {
            loader.show();
        }
        else {
            loader.hide();
        }
    }, [submittingPayment])

    const getCountryList = async () => {
        var result = await orderPlacementApi.getCountries();
        if (result.hasSuccess) {
            setCountryListState({
                countries: result.response
            });
        }
    }

    const loadPaymentOptions = async (plantId) => {
        if (paymentOptions == null) {
            var data = await orderPlacementApi.getPaymentOptions(globalOfferId.globalOfferId, plantId);
            setPaymentOptions(data.response);
        }
    }

    const loadSession = async () => {
        new ApiDataAccess("api/checkout").get(`/session/${globalOfferId.globalOfferId}`)
            .then(response => {
                setSession(response);
            });
    }

    const saveSession = async (session) => {
        var request = {
            session: session,
            globalOfferId: globalOfferId.globalOfferId
        };
        new ApiDataAccess("api/checkout").post(`/session`, request);
    }

    const initialize = async (plantId) => {
        console.log("PaymentStep initialize");
        if (plantId > 0) {
            let initialAddress = {};
            setCurrency(offer.supportedCountry.currency);
            setOrderNumber(offer.salesForceOpportunity.referenceNumber);

            if (offerContext?.billingAddress) {
                console.log("defaulting billing address to saved offer context", offerContext.billingAddress);
                reset({
                    billingAddress: offerContext.billingAddress
                })
            } else {
                var billingAddressFromServer = await orderPlacementApi.getBillingAddress(offer.salesForceAccountId);
                if (billingAddressFromServer.hasSuccess) {
                    reset({
                        billingAddress: {
                            name: billingAddressFromServer.response.name ? billingAddressFromServer.response.name : "",
                            addressLine1: billingAddressFromServer.response.street,
                            addressLine2: null,
                            city: billingAddressFromServer.response.city,
                            state: billingAddressFromServer.response.state,
                            postalCode: billingAddressFromServer.response.postalCode,
                            country: billingAddressFromServer.response.country,
                            email: billingAddressFromServer.response.email
                        }
                    });
                }
            }

            await getCountryList();

            await loadPaymentOptions(plantId);
            setCulture(offer.supportedCountry && offer.supportedCountry.cultureString ? offer.supportedCountry.cultureString : 'en-US');
            console.log(appSettings, appSettings.settings["Application:CompanyName"] );
            setLoaded(true);

            console.log("PaymentStep initialized", initialAddress);
        }
    }

    useEffect(() => {
        if (status !== 'success') {
            return;
        }
        if (!globalOfferId.globalOfferId) {
            return;
        }

        const load = async () => {
            console.log(orderIntent);
            if (getOriginalOrRoutedPlant() > 0) {

                var total = orderIntent && orderIntent.intentRequest && orderIntent.intentRequest.orderIntentTotals
                    ? Math.round(orderIntent.intentRequest.orderIntentTotals.partialPaymentAmount * 100)
                    : -1;

                setTotalAmount(total);

                if (total < 0) {
                    console.log("redirect because total amount of payment is -1");
                    document.location = `/offer/${globalOfferId.globalOfferId}`;
                }
                await initialize(getOriginalOrRoutedPlant());
            }
            else {
                console.log("plantId not found -- load failure");
            }
        }
        load();

    }, [offer, offerContext]);

    const onProcessed = async (status, result, containerId, paymentApiUrl, paymentMethod) => {
        switch (status) {
            case ResponseStatus.Rejected:
                var rc = getReasonCodeDescription(result.reasonCode);
                setReason(rc);

                document.getElementById(containerId).scrollIntoView();
                break;
            case ResponseStatus.Declined:
                if (result.reasonCode === ReasonCode.PaymentResubmitted) {
                    await onPaymentReceived(result, true, paymentMethod);
                }
                else {
                    var rc = getReasonCodeDescription(result.reasonCode);
                    setReason(rc);
                    document.getElementById(containerId).scrollIntoView();
                }
                break;
            case ResponseStatus.Approved:
            case ResponseStatus.ApprovedPending:
                console.log("Payment status of " + status, result);
                await onPaymentReceived(result, false, paymentMethod);
                break;
            default:
                throw new Error("Unable to determine a ResponseStatus from the PaymentForm. Unknown status of: " + status);
        }
        setSubmittingPayment(false);
    }

    const getReasonCodeDescription = (reasonCode) => {
        switch (reasonCode) {
            case ReasonCode.InvalidCardNumber:
                return <Translation id='6999aaa1-5afe-4392-b3cd-23336bb7cd1e' defaultMessage='The Card Number is invalid' category='Label' />;
            case ReasonCode.InvalidCvv:
                return <Translation id='9d7a39a6-b3e4-4f84-93cb-42bfbb17d56d' defaultMessage='The CVV is invalid' category='Label' />;
            case ReasonCode.InvalidExpirationDate:
                return <Translation id='51e39836-e20d-4439-8db0-5f3c3d78b00b' defaultMessage='The Expiration Date is invalid' category='Label' />;
            case ReasonCode.InvalidPostalCode:
                return <Translation id='6247002d-fa50-43f9-98c9-f64164fe7f34' defaultMessage='The Postal Code is invalid' category='Label' />;
            case ReasonCode.InvalidAccountNumber:
                return <Translation id='1ae3d64e-f783-484b-9d92-792b2886dc2b' defaultMessage='The Account Number is invalid' category='Label' />;
            case ReasonCode.InvalidRoutingNumber:
                return <Translation id='78be2898-1fa8-4373-95b1-ad1108309ed2' defaultMessage='The Routing Number is invalid' category='Label' />;
            case ReasonCode.InvalidAccountType:
                return <Translation id='3f6ab1fd-1dfa-4c07-9fe6-76987a7c2ced' defaultMessage='The Account Type is invalid' category='Label' />;
            default:
                return <Translation id='a22c8ff4-6ec0-4d70-8803-2921eaa82fbc' defaultMessage='Your Payment was declined. Please try again or use a different payment method.' category='Label' />;
        }
    }

    const onSubmit = async (data, errors) => {

        trackEvent({ ecommerce: null });  // Clear the previous ecommerce object.

        trackEvent({
            event: 'add_payment_info',
            ecommerce: {
                items: [
                    {

                        item_id: offer.product.id,
                        item_name: offer.product.value,
                        currency: offer.plant.currency,
                        //  index: quoteCount,            //the nth quote added, starting at 1
                        unit_price: offer.calculationBreakdown[0].price.unitPrice.amount,
                        item_category: offer.productGroup.value,  //what packaging category
                        item_category2: analyticsMetadata.industryCategoryName,      //what packaging sub-category
                        item_category3: offer.convertingMethod.componentType.value,     //pouch type
                        item_category4: offer.dimensions.width + " x " + offer.dimensions.length,      // width x length
                        price: totalAmount,
                        quantity: offer.calculationBreakdown[0].quantityBreak,
                        skuCount: offer.skuCount
                    }
                ],
                currency: currency,
                value: totalAmount,
                payment_type: paymentOption.config.paymentMethod
            },
            userId: cookies.visitorId
        });

        //loader.show();
        setReason(null);
        setSubmittingPayment(true);

        console.group("%c payment submission", 'background: #333; color: #bada55');

        console.log("PaymentStep - onSubmit", data);

        if (paymentOption) {
            setBillingAddress(data.billingAddress);

            //NOTE: The code below here can be used when developing locally to simulate a call to the back end for testing
            // const delay = ms => new Promise(res => setTimeout(res, ms));
            // await delay(5000);

            try {
                await paymentOption.submit(data.billingAddress);
            }
            catch (err) {
                console.log("submit fail", err)
                setSubmittingPayment(false);
            }
            //loader.hide();
            console.log("payment submission end", data);
            console.groupEnd();
        }
        else {
            document.location = `/offer/${globalOfferId.globalOfferId}`;
        }
        console.groupEnd();


    }

    const isArtworkUploadComplete = async () => {
        var apiResponse = await orderPlacementApi.getArtworkSkus(globalOfferId.globalOfferId);
        var skus = apiResponse.response;

        if (skus && skus.length > 0) {
            var sku = skus ? skus.find(x => x.hasArtworkData == false) : null;
            return sku ? false : true;
        }
        return false;
    }

    const onPaymentReceived = async (status, resubmitted, paymentMethod) => {
        loader.hide();
        completePopup.current.show();

        console.log('STATUS', status, resubmitted, paymentMethod);

        console.group("%c payment received", 'background: #333; color: #bada55');

        if (!resubmitted) {

            var request = {
                paymentTransactionId: status.transactionId,
                billingAddress: billingAddress
            };

            await orderPlacementApi.applyPaymentToOrder(globalOfferId.globalOfferId, request);

            await sendPaymentReceipt(paymentMethod, billingAddress, `${status.transactionId}`);
            var hasArtwork = await isArtworkUploadComplete();

            if (hasArtwork) {
                trackEvent({
                    event: 'purchase',
                    ecommerce: {
                        items: [
                            {
                                item_id: offer.product.id,
                                item_name: offer.product.value,
                                currency: offer.plant.currency,
                                //  index: quoteCount,            //the nth quote added, starting at 1
                                unit_price: offer.calculationBreakdown[0].price.unitPrice.amount,
                                item_category: offer.productGroup.value,  //what packaging category
                                item_category2: analyticsMetadata.industryCategoryName,      //what packaging sub-category
                                item_category3: offer.convertingMethod.componentType.value,     //pouch type
                                item_category4: offer.dimensions.width + " x " + offer.dimensions.length,      // width x length
                                price: offer.calculationBreakdown[0].price.totalPrice.amount,
                                quantity: offer.calculationBreakdown[0].quantityBreak,
                                skuCount: offer.skuCount
                            }
                        ],
                        transaction_id: orderReference.orderNumber,
                        value: orderIntent.intentRequest.orderIntentTotals.grandTotalAmount,     //value is the price from items array minus discount amount
                        //  tax: orderIntent.orderIntentTotals.
                        shipping: orderIntent.intentRequest.orderIntentTotals.shippingAmount,
                        currency: offer.plant.currency
                    },
                    userId: cookies.visitorId
                });
            }

        }
        console.groupEnd();

        if (fromWeb) {
            document.location = `/offer/${globalOfferId.globalOfferId}`;
        }
        else {
            // screenContext.hideLoader();
            setTimeout(() => {
                document.location = '/';
            }, 5000);
        }
    }

    const sendPaymentReceipt = async (paymentMethod, billingAddress, reference) => {

        var dataAccess = new ApiDataAccess('api/email');
        var request = {
            billingAddress: billingAddress,
            paymentMethod: PaymentMethod.toString(paymentMethod),
            reference: `${reference}`,
            config: paymentOption.config.paymentProviderMetaData
        };

        await dataAccess.post(`/${globalOfferId.globalOfferId}/paymentReceipt`, request);
    }


    const requiresPayment = () => {
        return totalAmount > 0 && orderIntent.paymentStateId !== 'COMPL';
    }


    //NOTE: This is for disabling the Submit button when the payment form had an error loading and the intitialize method
    //didn't run. Once initialized, this value will be set to false
    const [nextButtonDisabled, setNextButtonDisabled] = useState(requiresPayment());

    const getPaymentAmount = () => {
        if (offer.calculationBreakdown[0].initiallyDue) {
            return totalAmount;
        }
        else {
            if (offer.salesForceOpportunity.hasOwnProperty('initialPaymentDuePercentage')) {
                return Math.round(totalAmount * (offer.salesForceOpportunity.initialPaymentDuePercentage / 100))
            }
            else {
                switch (offer.salesForceOpportunity.paymentTerms) {
                    case PaymentTerms.Net30_50_50:
                        return Math.round(totalAmount / 2);
                    default:
                        return totalAmount;
                }
            }
        }
    }

    const formatCurrency = (value) => {
        return FormatProvider.FormatDecimalWithCommas(value.toFixed(2), 2);
    }

    const onLoading = (loading) => {
        if (loading) {
            // screenContext.showLoader();
        }
        else {
            // screenContext.hideLoader();
        }
    }

    const renderNextStepButton = () => {
        if (requiresPayment()) {
            return (<button
                data-testid="order-placement-pay-for-order-button"
                type="submit" className='btn btn-primary pull-right' disabled={isSubmitting || submittingPayment} onClick={() => {
                handleSubmit(async (data, errors) => {
                    trackEvent({
                        event: 'ordering_tool_interactions',
                        eventProps: {
                            'step': 'placement_4',
                            'product_category': analyticsMetadata.industryName,
                            'product_sub_category': analyticsMetadata.industryCategoryName,
                            'element': 'submit_payment'
                        },
                        userId: analyticsMetadata.visitorId
                    });


                   await onSubmit(data, errors);
                })
            }}>Submit</button>);
        }
        else {
            if (fromWeb) {
                return (<button
                    data-testid="order-placement-pay-for-order-button"
                    className='btn btn-primary pull-right' onClick={() => {
                    document.location = `/offer/${globalOfferId.globalOfferId}`
                }}>Back</button>);
            }
            return (<button
                data-testid="order-placement-pay-for-order-button"
                className='btn btn-primary pull-right' onClick={(e) => {
                e.preventDefault();
                window.location = appSettings.settings["Application:HomePageUrl"];
                return false;
            }}>Home</button>);
        }
    }

    const getPlantName = () => {

        if (offerContext && offerContext.routedPlant && offerContext.routedPlant.plantName) {
            return offerContext.routedPlant.plantName;
        }

        if (offer && offer.plant && offer.plant.name) {
            return offer.plant.name
        }

        return null;
    }

    const getOriginalOrRoutedPlant = () =>
    {
        if (orderIntent.intentRequest.routedPlant.plantId > 0) {
            return orderIntent.intentRequest.routedPlant.plantId;
        }
        else
        {
            return offer.plant.id;
        }
    }

    return (
        <div>
            {loaded && offer && orderIntent && status === 'success' && getOriginalOrRoutedPlant() > 0 &&
                initialized && loadOfferContextStatus === 'success' &&
                <div>
                    <ShoppingBreadcrumbs active={3}  />
                    <OrderReference orderReference={orderReference} />

                    {totalAmount > 0 && offerContext?.billingAddress &&
                        <ProformaInvoiceLink globalOfferId={globalOfferId.globalOfferId} billingAddress={offerContext.billingAddress} />
                    }

                    <form onSubmit={handleSubmit(onSubmit)}>

                        {requiresPayment()
                            ?
                            <div>
                                <Alert type='info'><Translation id='64bf69ce-9075-4e11-94d0-40e9c5b7d7f1' defaultMessage='A payment of {0} {1} is required in order to proceed.' category='Label' values={{ 0: currency, 1: formatCurrency(getPaymentAmount() / 100) }} /></Alert>
                                {paymentOptions && requiresPayment() &&
                                    <PaymentOptionGroup
                                        shippingAddress={billingAddress}
                                        data={offer}
                                        paymentOptions={paymentOptions}
                                        totalAmount={getPaymentAmount()}
                                        currency={currency}
                                        orderNumber={orderNumber}
                                        session={session}
                                        saveSessionCallback={saveSession}
                                        /*
                                            NOTE: Below here, the plantId comes from the orderIntent and the plant name comes from the offer context
                                            The plantId most likely can be switched over to the offerContext after the app has been deployed, as
                                            there may be lingering offers that haven't been submitted yet. The plant name that comes from offer.plant.name
                                            also can be removed after those lingering offers are completed.
                                        */
                                        plantId={getOriginalOrRoutedPlant()}
                                        plantName={getPlantName()}
                                        culture={culture}
                                        expandedIndex={expandedIndex}
                                        onProcessed={onProcessed}
                                        onPaymentMethodChanged={(idx, paymentOption) => {

                                            if (paymentOption && paymentOption.paymentMethod) {
                                                var paymentMethod = 'credit_card';

                                                if (paymentOption.paymentMethod.toLowerCase() === 'check') {
                                                    paymentMethod = 'check';
                                                }

                                                trackEvent({
                                                    event: 'ordering_tool_interactions',
                                                    eventProps: {
                                                        'step': 'ordering_tool_interactions',
                                                        'product_category': analyticsMetadata.industryName,
                                                        'product_sub_category': analyticsMetadata.industryCategoryName,
                                                        'selection': paymentMethod,
                                                    },
                                                    userId: analyticsMetadata?.visitorId
                                                });
                                            }

                                            setNextButtonDisabled(true);
                                        }}
                                        onSelected={po => {
                                            console.log("Payment iframe initialized");
                                            setPaymentOption(po);
                                            setNextButtonDisabled(false);
                                        }}
                                        onLoading={onLoading} />
                                }

                                <Card title={<Translation id='22eaf737-ba3e-405f-9a45-427fe58d0e7c' defaultMessage='Billing Address' category='Card Title' />} subtitle={<Translation id='64aa898e-2a83-4656-b39b-a2cb63f34dd8' defaultMessage='Please enter your Billing Address' category='Card Sub Title' />}>
                                    <HookEditAddress
                                        unregister={unregister}
                                        getErrorMessage={get}
                                        setValue={setValue}
                                        getValues={getValues}
                                        register={register}
                                        countriesForStateValidation={CountriesWithSubSelection}
                                        errors={errors}
                                        countries={countries.countries}
                                        id="billingAddress"
                                        addressType="billingAddress"
                                        orderPlacementApi={orderPlacementApi}
                                        reset={reset}
                                        requireEmail={true}
                                        requireCompanyName={true}
                                        control={control}
                                    // onCountryChanged={async (e) => {
                                    //     await onSelectedCountryChanged("shippingAddress", e);
                                    // }}
                                    />
                                </Card>

                            </div>
                            :
                            <Card title={<Translation id='7f45f9e4-2a16-48fd-afc1-c5c7d40e04ae' defaultMessage='Payment' category='Card Title' />} >
                                <p><Translation id='181a4c7f-5a96-404d-b1de-cf2076fc7de3' defaultMessage='A payment is currently not required.' category='Label' /></p>
                            </Card>
                        }

                        {reason &&
                            <div className='alert error'>
                                <div className='alert-content'>{reason}</div>
                            </div>
                        }

                        {/*{props.source === 'website' &&*/}
                        {/*    <button className='btn btn-tertiary-outline back' onClick={() => { document.location = `/offer/${globalOfferId.globalOfferId}` }}>Back</button>*/}
                        {/*}*/}

                        {renderNextStepButton()}

                    </form>

                </div>
            }

            <Popup ref={completePopup} preventClickAway={true}>
                <div className='contentSection'>
                    <h3>Your Payment</h3>
                    <p>We are processing payment. Please don't refresh or navigate away page</p>
                </div>
            </Popup>

        </div>
    )
}





export class PaymentOptionGroup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expandedIndex: 0,
            options: this.props.paymentOptions
        }

        this.onLoading = this.onLoading.bind(this);
    }

    onChange(idx, expanded, paymentOption) {
        this.setState({ options: null });

        if (expanded) {
            this.setState({
                expandedIndex: idx
            })
        }

        this.forceUpdate(() => {
            this.setState({ options: this.props.paymentOptions });
            if (this.props.onPaymentMethodChanged) {
                this.props.onPaymentMethodChanged(idx, paymentOption);
            }
        });

    }

    onLoading(loading) {
        if (this.props.onLoading) {
            this.props.onLoading(loading);
        }
    }

    render() {
        return (
            <div>
                {this.state.options &&
                    <div>
                        {this.state.options.map((option, idx) => {
                            return (
                                <PaymentOption
                                    key={`po${idx}`}
                                    id={`po${idx}`}
                                    data={this.props.data}
                                    config={option}
                                    plantId={this.props.plantId}
                                    plantName={this.props.plantName}
                                    shippingAddress={this.props.shippingAddress}
                                    totalAmount={this.props.totalAmount}
                                    currency={this.props.currency}
                                    culture={this.props.culture}
                                    orderNumber={this.props.orderNumber}
                                    session={this.props.session}
                                    saveSessionCallback={this.props.saveSessionCallback}
                                    expanded={idx === this.state.expandedIndex}
                                    disabled={this.state.options.length === 1 || (idx === this.state.expandedIndex)}
                                    onInit={po => { this.props.onSelected(po); }}
                                    onProcessed={(status, data, containerId, paymentApiUrl, paymentMethod) => {
                                        this.props.onProcessed(status, data, containerId, paymentApiUrl, paymentMethod);
                                    }}
                                    onChange={expanded => {
                                        this.onChange(idx, expanded, option)
                                    }}
                                    onLoading={() => { this.onLoading(true) }}
                                    onLoaded={() => { this.onLoading(false) }}
                                />
                            )
                        })}
                    </div>
                }
            </div>
        )
    }
}


export class PaymentOption extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: false
        }

        this.payment = null;
        this.self = this;
        this.config = this.props.config;
        this.containerId = 'card_' + this.config.paymentMethod;
        this.data = props.data;

        this.onPreInit = this.onPreInit.bind(this);
        this.onInit = this.onInit.bind(this);
        this.onProcessed = this.onProcessed.bind(this);

    }

    getConfig() {

        var result = {
            backgroundColor: '#FFFFFF',
            offerKey: this.data.key,
            plantId: this.props.plantId,
            salesForceAccountId: this.data.salesForceAccountId,
            salesForceAccountName: this.data.salesForceAccountName,
            salesForceOpportunityId: this.data.salesForceOpportunityId,
            shippingAddress: this.props.shippingAddress,
            plantName: this.props.plantName,
            region: this.props.culture ? this.props.culture : 'en-US'
        }

        this.config.paymentProviderMetaData.forEach(item => {
            // this loop was overriding plantId and plantName with incorrect meta data
            if (item.propertyName !== "plantId" && item.propertyName !== "plantName") {
                result[item.propertyName] = item.value;
            }
        });

        return result;
    }

    getTitle() {
        switch (this.config.paymentMethod) {
            case PaymentMethod.CreditCard:
                return <Translation id='119cfa8d-29b9-4aaf-bb54-76ddc584a64d' defaultMessage='Credit Card' category='Card Title' />;
            case PaymentMethod.ACH:
                return <Translation id='ce4ff197-67c3-4587-9a6d-9ae3ad66963f' defaultMessage='ACH' category='Card Title' />;
            case PaymentMethod.eCheck:
                return <Translation id='886e93a7-f38c-4ef5-9628-720923d5df63' defaultMessage='eCheck' category='Card Title' />;
            case PaymentMethod.Check:
                return <Translation id='0a77d2ed-c1ca-4688-9773-db288a481aab' defaultMessage='Check or ACH' category='Card Title' />;
            default:
                throw new Error(this.config.paymentMethod + ' is not defined!');
        }
    }

    getSubtitle() {
        switch (this.config.paymentMethod) {
            case PaymentMethod.CreditCard:
                return <Translation id='f75b43dd-871d-4e1c-8c0d-87a88556765c' defaultMessage='Enter your Credit Card information' category='Card Sub Title' />;
            case PaymentMethod.ACH:
                return <Translation id='12b12f4d-a962-4893-bef2-912628e4873c' defaultMessage='Enter your ACH information' category='Card Sub Title' />;
            case PaymentMethod.eCheck:
                return <Translation id='4ff1739b-8f75-48aa-a9ed-c8a9348e73fa' defaultMessage='Enter your eCheck information' category='Card Sub Title' />;
            case PaymentMethod.Check:
                return '';
            default:
                throw new Error(this.config.paymentMethod + ' is not defined!');
        }
    }

    onPreInit() {
        if (this.props.onLoading) {
            this.props.onLoading();
        }
    }

    onInit(p) {
        this.payment = p;

        if (this.payment) {
            this.setState({ error: false });
            if (this.props.onInit) {
                this.props.onInit(this);
            }
        }
        else {
            this.setState({ error: true });
            if (this.props.onError) {
                this.props.onError();
            }
        }

        if (this.props.onLoaded) {
            this.props.onLoaded();
        }
    }

    onProcessed(status, data, containerId, paymentApiUrl) {

        if (this.props.onProcessed) {
            this.props.onProcessed(status, data, containerId, paymentApiUrl, this.config.paymentMethod);
        }
    }

    submit(billingAddress) {

        var payload = {
            config: this.payment.config,
            billingAddress: billingAddress,
            shippingAddress: this.props.shippingAddress,
        };

        this.payment.submit(payload);
    }

    getHeight() {
        switch (this.config.paymentMethod) {
            case PaymentMethod.ACH:
                return 200;
            case PaymentMethod.eCheck:
                return 150;
            case PaymentMethod.CreditCard:
                return 400;
            case PaymentMethod.Check:
                return 500;
        }
    }

    render() {
        return (
            <ExpandableCard id={this.containerId} title={this.getTitle()} subtitle={this.getSubtitle()} expanded={this.props.expanded === true} disabled={this.props.disabled} onChange={this.props.onChange}>
                <PaymentComponent
                    id={this.config.paymentMethod}
                    paymentApiUrl={this.config.paymentApiUrl}
                    onPreInit={() => { this.onPreInit() }}
                    onInit={p => {
                        console.log("PaymentComponent onInit", this.config);
                        this.onInit(p);
                    }}
                    onProcessed={(status, data) => {
                        this.onProcessed(status, data, this.containerId, this.config.paymentApiUrl, this.config.paymentMethod);
                    }}
                    provider={this.config.paymentProvider}
                    method={this.config.paymentMethod}
                    config={this.getConfig()}
                    orderNumber={this.props.orderNumber}
                    session={this.props.session}
                    saveSessionCallback={this.props.saveSessionCallback}
                    amount={this.props.totalAmount}
                    currency={this.props.currency}
                    height={this.getHeight()}
                />

                {this.state.error &&
                    <Alert type='info'><Translation id='a201ad41-9bf6-4241-8db6-d3da08779d87' defaultMessage='This payment method is currently not available. Please use an alternative payment method or call Sales Support.' category='Label' /></Alert>
                }
            </ExpandableCard>
        )
    }
}