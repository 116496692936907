import React, { useContext } from "react";
import { Language } from "../../infrastructure/Languages";
import { Card } from "../../core/Card";
import { SectionTitle } from "../../core/SectionTitle";
import { Row } from "../../core/Row";
import { Column } from "../../core/Column";
import { SectionLabel } from "../../core/SectionLabel";
import { DownloadLink } from "../../core/DownloadLink";
import { ProductCertificationsDisplay } from "./ProductCertificationsDisplay";
import './SpecificationsDisplay.css';

export const SpecificationsDisplay = ({
    dimensions,
    eyemark,
    roundedCorners,
    sealWidth,
    qrCodeType,
    doubleCut,
    gussetType,
    bottomFill,
    core,
    sealWrapType,
    colorSpec,
    structureSpecId,
    structureSpecUrl,
    skuCount,
    outsourcedFeatures,
    isRollStock,
    cultureString,
    googleAnalyticsContext,
    trackHelp,
    certificates }) => {

    const language = useContext(Language);

    return (
        <div className="card">
        <Card
            title={language.translateLabel("357a8a69-4bb5-4068-9541-89cb26b6d458", "Specifications")}
            subtitle={language.translateLabel(
                "d32a45ca-4148-4aad-8657-b559548f73c1",
                "If any of the below specifications need to be changed, please contact your sales representative"
            )}>

            <Row>
                <Column>
                    <SectionTitle cms="dimensions" gaContext={googleAnalyticsContext.CreateContext("Dimensions")}>
                        {language.translateLabel("5ab1fc30-0933-4425-bad4-b7e94887935d", "Dimensions")}
                    </SectionTitle>
                    <div className='sectionLabel'>
                        <div className="product-description">{dimensions}</div>
                    </div>
                </Column>

                <Column>
                    <SectionTitle cms="material" gaContext={googleAnalyticsContext.CreateContext("Material")}>
                        {language.translateLabel("237f31ad-e94d-4024-92b0-83b915ec9c00", "Material")}
                    </SectionTitle>
                    <SectionLabel description={"SPEC-" + structureSpecId}>
                        <DownloadLink
                            href={`${structureSpecUrl}?culture=${cultureString}`}
                            onClick={() => {
                                if (trackHelp)
                                    trackHelp("Information Sheet");
                            }}>
                            {language.translateLabel("0717f632-37ca-4e42-832c-689ee4841fdb", "Information Sheet")}
                        </DownloadLink>
                    </SectionLabel>
                </Column>
            </Row>
            <Row>
                <Column>
                    <SectionTitle cms="epac-connect" gaContext={googleAnalyticsContext.CreateContext("ePac Connect")}>
                        {language.translateLabel("9d37c5ce-4a1e-4997-9bcb-2541ab4679bc", "ePac Connect")}
                    </SectionTitle>
                    <SectionLabel description={qrCodeType} />
                </Column>
                <Column>
                    <SectionTitle cms='additional-features' gaContext={googleAnalyticsContext.CreateContext("Additional Features")} category='Label'>
                        {language.translateLabel("b91d4109-3f7f-456f-8151-60d5d1637322", "Additional Features")}
                    </SectionTitle>
                    <SectionLabel description={outsourcedFeatures && outsourcedFeatures.length > 0 ? outsourcedFeatures.map(x => x.value).join(', ') : "None"} />
                </Column>
            </Row>

            {
                isRollStock ?
                    <Row>
                        <Column>
                            <SectionTitle cms="core" gaContext={googleAnalyticsContext.CreateContext("Core")}>
                                {language.translateLabel("349e0a08-0587-4e0b-beb4-a9c7afd15820", "Core")}
                            </SectionTitle>
                            <SectionLabel description={core} />
                        </Column>
                        <Column>
                            <SectionTitle cms="seal-wrap-type" gaContext={googleAnalyticsContext.CreateContext("Seal Wrap Type")}>
                                {language.translateLabel("982ebc54-f5a4-4457-84cb-8c79a10abef1", "Seal Wrap Type")}
                            </SectionTitle>
                            <SectionLabel description={sealWrapType} />
                        </Column>
                    </Row>
                    :
                    <>
                        <Row>
                            <Column>
                                <SectionTitle cms="color-specification" gaContext={googleAnalyticsContext.CreateContext("Color Specification")}>
                                    {language.translateLabel("a621f4d8-8783-4852-acb9-29007f7a3d0f", "Color Specification")}
                                </SectionTitle>
                                <SectionLabel description={colorSpec} />
                            </Column>
                            <Column>
                                <SectionTitle cms="rounded-corners" image="2c791cd2-b235-4f29-81c8-8ae84900aa75" gaContext={googleAnalyticsContext.CreateContext("Rounded Corner")}>
                                    {language.translateLabel("7c696bb1-2fc7-477a-b9fe-ec25c674d91d", "Rounded Corners")}
                                </SectionTitle>
                                <SectionLabel description={roundedCorners} />
                            </Column>
                        </Row>

                        <Row>
                            <Column>
                                <SectionTitle cms="seal-width" image="debd99a5-6ad0-49fc-85c5-0b0e0af723ce" gaContext={googleAnalyticsContext.CreateContext("Seal Width")}>
                                    {language.translateLabel("fbe5f534-ba05-4a65-b3cc-385b63e59254", "Seal Width")}
                                </SectionTitle>
                                <SectionLabel description={sealWidth} />
                            </Column>
                            <Column>
                                <SectionTitle cms="bottom-fill" gaContext={googleAnalyticsContext.CreateContext("Bottom Fill")}>
                                    {language.translateLabel("1b929272-0f00-4c8c-b3ae-b1424beae707", "Bottom Fill")}
                                </SectionTitle>
                                <SectionLabel description={bottomFill} />
                            </Column>
                        </Row>

                        <Row>
                            <Column>
                                <SectionTitle cms="double-cut" gaContext={googleAnalyticsContext.CreateContext("Double Cut")}>
                                    {language.translateLabel("911343ac-bc2e-44bd-91ae-3152847d469a", "Double Cut")}
                                </SectionTitle>
                                <SectionLabel description={doubleCut} />
                            </Column>

                            <Column>
                                <SectionTitle cms="gusset-type" gaContext={googleAnalyticsContext.CreateContext("Gusset Type")}>
                                    {language.translateLabel("e3af51f1-9f65-4b02-aac3-a8e3ff5cf7d0", "Gusset Type")}
                                </SectionTitle>
                                <SectionLabel description={gussetType} />
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                <SectionTitle cms="eyemark-placement" gaContext={googleAnalyticsContext.CreateContext("Eyemark Placement")}>
                                    {language.translateLabel("af463558-b3b7-4d24-a547-3764d1d7431c", "Eyemarks")}
                                </SectionTitle>
                                <SectionLabel description={eyemark} />
                            </Column>
                            <Column>
                                {skuCount &&
                                    <>
                                        <SectionTitle cms="sku-count" gaContext={googleAnalyticsContext.CreateContext("SKU Count")}>
                                            {language.translateLabel("b8b51cda-bc7c-4798-9b17-4534caa25aaa", "SKU Count")}
                                        </SectionTitle>
                                        <SectionLabel description={skuCount} />
                                    </>
                                }
                            </Column>                          
                        </Row>
                        {certificates && certificates.length > 0 &&
                            <Row>
                                <Column>
                                    <ProductCertificationsDisplay certificates={certificates} googleAnalyticsContext={googleAnalyticsContext.CreateContext("Certificates")} language={language} />
                                </Column>
                            </Row>
                        }
                    </>
            }
        </Card>
        </div>
    );
};