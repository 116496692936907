import { useEffect, useState, useContext, useRef } from "react";
import { React } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthenticatedTemplate } from "@azure/msal-react";
import { RequiredRegistrationTextbox } from "../../components/registration/RequiredRegistrationTextbox";
import { ContactRegistrationTextbox } from "../../components/registration/RequiredRegistrationTextbox";
import { RegistrationSelectionDropdown } from '../../components/registration/RegistrationSelectionDropdown'
import { useAuthContext } from "../../infrastructure/AuthContext";
import { VerifyUserAccountDisabled } from "./VerifyUserAccountDisabled";
import { SecureApiDataAccess } from "../../infrastructure/SecureApiDataAccess";
import { CapabilityName } from '../../infrastructure/Constants';
import { CapabilitiesContext, SettingsContext } from '../../infrastructure/Contexts';
import { MyAccountValidationQuestions } from "../../infrastructure/Constants";
import { Language } from '../../infrastructure/Languages';
import { Popup } from '../../core/Popup';
import { Check, AlertTriangle } from 'react-feather';
import { Translation } from '../../core/Translation';

import './VerifyUserAccountPage.css'

export const VerifyUserAccountPage = ({ onError, submitError }) => {

    const language = useContext(Language);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const settings = useContext(SettingsContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const popup = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const userDataAccess = new SecureApiDataAccess("/api/user");
    const estimateDataAccess = new SecureApiDataAccess("/api/estimates");
    const { getLoggedUser } = useAuthContext();

    const [user, setUser] = useState();
    const [myAccountRegistrationEnabled, setMyAccountRegistrationEnabled] = useState(false);
    const [hasSubmitFailed, setHasSubmitFailed] = useState(false);
    const [ready, setReady] = useState(false);
    const [contactSubmissionResult, setContactSubmissionResult] = useState(false);
    const [showFormSubmittedMessage, setShowFormSubmissionMessageEnable] = useState(true);

    if (!location.state || !location.state.culture)
        console.log("missing culture parameter, registration is not possible");
    const culture = location?.state?.culture;

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        defaultValues: {
            salesforceGlobalAccountNumber: null,
            validationQuestion: null,
            validationAnswer: null
        },
    });

    const { handleSubmit: handlePopupSubmit, control: popupcontrol, } = useForm({
        mode: "onChange",
        defaultValues: {
            firstname: null,
            lastname: null,
            company: null,
            phone: null,
            email: null,

        },
    });

    useEffect(() => {
        const init = async () => {
            var user = await getLoggedUser();
            if (user.salesforceAccountId)
                navigate('/myorders');  // user does not need to be linked to a salesforce account
            setUser(user);

            // check if my account registration is enabled
            if (culture)
                await estimateDataAccess.get(`/feature-settings/${culture}`)
                    .then(response => {
                        setMyAccountRegistrationEnabled(response.data.myAccountRegistrationEnabled);
                    })
                    .finally(setReady(true));
        }
        init();
    }, []);

    const verifyUserIsLinkedToSalesforceAccount = async () => {
        var user = await getLoggedUser();
        setUser(user);
        return !!user.salesforceAccountId;
    }

    /*
    const onFormError = (errors, e) => {
    };
    */

    const onValidationTypeChanged = (e) => {
        setValue("validationQuestion", e.target.selectedIndex);
    }

    const onSubmitForm = (data, e) => {
        loader.show();
        var request = {
            culture: culture,
            userId: user.userId,
            salesforceGlobalAccountNumber: data.salesforceGlobalAccountNumber,
            validationQuestion: data.validationQuestion,
            validationAnswer: data.validationAnswer
        };

        userDataAccess.put('/account/salesforce', request)
            .then(response => {
                verifyUserIsLinkedToSalesforceAccount().then(response => {
                    loader.hide();
                    if (response)
                        navigate('/myorders');
                    else
                        setHasSubmitFailed(true);
                })
            })
            .catch(ex => {
                setHasSubmitFailed(true);
                loader.hide();
            })
    };

    const onSubmitContactForm = (data, e) => {
        loader.show();
        var request = {
            firstname: data.firstname,
            lastname: data.lastname,
            company: data.company,
            phone: data.phone,
            email: data.email,
            culture: culture,
        };

        loader.hide();

        userDataAccess.postAsJson('/account/signup/issue', request)
            .then(response => {
                if (response) {
                    setContactSubmissionResult(true);
                    setShowFormSubmissionMessageEnable(false);
                    loader.hide();
                }
                else {
                    setContactSubmissionResult(false);
                    setShowFormSubmissionMessageEnable(false);
                    document.getElementById("contact-form").reset();
                    loader.hide();
                }
            })
            .catch(ex => {
                setHasSubmitFailed(true);
                loader.hide();
            })
    }


    const onHavingTroubleClick = () => {
        popup.current.show();
    }

    const getContactSubmissionResponse = () => {
        switch (contactSubmissionResult) {
            case true:
                return <Translation id='4b440089-1e0f-41d9-9ed9-99da6573116d' defaultMessage='Your help request has been sent! Someone from Epac will reach out to you shortly...Thank You!' category='Label' />;
            case false:
                return <Translation id='43dd770d-4233-44f7-9101-d4066b47c642' defaultMessage='Sorry, there was an issue sending your request. Please try again!' category='Label' />;
            default:
                return <Translation id='4b440089-1e0f-41d9-9ed9-99da6573116d' defaultMessage='Your help request has been sent! Someone from Epac will reach out to you shortly...Thank You!' category='Label' />;
        }
    }
    const getContactSubmissionHeaderResponse = () => {
        switch (contactSubmissionResult) {
            case true:
                return <h4><Check /></h4>
            case false:
                return <h4><AlertTriangle /></h4>;
            default:
                return <h4></h4>
        }
    }

    return (
        <div className="registration-page">
            {
                ready &&
                <AuthenticatedTemplate>
                    {
                        !myAccountRegistrationEnabled ?
                            <VerifyUserAccountDisabled />
                            :
                            <>
                                <h3>{language.translateLabel('da2470c1-d295-45ce-a2c7-cc73b36381f5', 'Account Verification', 'Label')}</h3>

                                <form className="registration-container" onSubmit={handleSubmit(onSubmitForm, null)}>
                                    <div className="registration-grid-item">
                                        <RequiredRegistrationTextbox
                                            control={control}
                                            placeHolder={language.translateLabel('01491a05-319c-49f1-83a3-cc72c4a7ac66', 'Account Number', 'Label')}
                                            label={language.translateLabel('01491a05-319c-49f1-83a3-cc72c4a7ac66', 'Account Number', 'Label')}
                                            rules={{ required: language.translateLabel('54b71d43-6681-40ba-90a5-72b81d85fdf2', 'Required', 'Label') }}
                                            name="salesforceGlobalAccountNumber"
                                            helpContext="accountNumber"
                                        />
                                    </div>
                                    <div className="registration-grid-item">
                                        <RegistrationSelectionDropdown
                                            data={MyAccountValidationQuestions}
                                            control={control}
                                            label={language.translateLabel('cfeb734b-7366-4cdc-9983-b7d399fb26e2', 'Choose Document', 'Label')}
                                            rules={{ required: language.translateLabel('54b71d43-6681-40ba-90a5-72b81d85fdf2', 'Required', 'Label') }}
                                            name="validationQuestion"
                                            onValueChanged={onValidationTypeChanged}
                                        />
                                    </div>
                                    <div className="registration-grid-item">
                                        <RequiredRegistrationTextbox
                                            control={control}
                                            placeHolder={language.translateLabel('8e20e655-6ad0-4c04-83b4-c5e8958f73d7', 'Document Number', 'Label')}
                                            label={language.translateLabel('8e20e655-6ad0-4c04-83b4-c5e8958f73d7', 'Document Number', 'Label')}
                                            rules={{ required: language.translateLabel('54b71d43-6681-40ba-90a5-72b81d85fdf2', 'Required', 'Label') }}
                                            name="validationAnswer"
                                            helpContext="documentnumber"
                                        />
                                    </div>
                                    {submitError && submitError.length > 0 && (
                                        <div className="registration-notifications">
                                            <ul className="registration-validation-error">
                                                {submitError.map((item, idx) => <li key={idx}>{item}</li>)}
                                            </ul>
                                        </div>
                                    )}
                                    <div className="registration-submit">
                                        <button type="submit" className="btn btn-primary">
                                            {language.translateLabel('eb0cd90c-5634-4ee0-9412-f02e5ecb8051', 'Submit', 'Label')}
                                        </button>
                                    </div>

                                    </form>

                                    <div className="registration-error">
                                        {
                                            hasSubmitFailed &&
                                            <Translation id='ecdc7c59-b865-45e0-8569-a745aa722ecc' defaultMessage='Sorry, we were not able to verify your account with this information' category='Label' />
                                         
                                        }
                                    </div>

                                <div className="registration-link">
                                    <p onClick={onHavingTroubleClick}>{language.translateLabel('e70c5b39-e2ca-49ad-a679-0a3ae2807615', 'Having Trouble Signing Up?', 'Label')}</p>
                                </div>
                                <div className="registration-link">
                                    <a href={settings.settings["Application:NewCustomerRedirectUrl"]}>{language.translateLabel('0c88ba47-c049-4f01-a639-c4318e410a6c', 'New Customer?', 'Label')}</a>
                                </div>
                               


                                <Popup closeOnClickAway={true} ref={popup}>
                                    {showFormSubmittedMessage ?
                                        <form id="contact-form" onSubmit={handlePopupSubmit(onSubmitContactForm, null)}>
                                            <div className="topSectionCentered"> <h4>{language.translateLabel('5f1d38a4-2a8e-4ece-833a-7b6297c27cbb', 'We Will Contact You', 'Label')}</h4></div>
                                            <div className='contentSection'>

                                                <div className="contact-form-verification-grid-item">
                                                    <ContactRegistrationTextbox
                                                        control={popupcontrol}
                                                        placeHolder={language.translateLabel('ce014de7-4ecf-4960-b753-d9e253234b3c', 'First Name', 'Label')}
                                                        label={language.translateLabel('ce014de7-4ecf-4960-b753-d9e253234b3c', 'First Name', 'Label')}
                                                        rules={{ required: language.translateLabel('54b71d43-6681-40ba-90a5-72b81d85fdf2', 'Required', 'Label') }}
                                                        name="firstname"
                                                    />
                                                </div>
                                                <div className="contact-form-verification-grid-item">
                                                    <ContactRegistrationTextbox
                                                        control={popupcontrol}
                                                        placeHolder={language.translateLabel('cedc02f5-9290-4fa0-9107-c052919a958c', 'Last Name', 'Label')}
                                                        label={language.translateLabel('cedc02f5-9290-4fa0-9107-c052919a958c', 'Last Name', 'Label')}
                                                        rules={{ required: language.translateLabel('54b71d43-6681-40ba-90a5-72b81d85fdf2', 'Required', 'Label') }}
                                                        name="lastname"
                                                    />
                                                </div>
                                                <div className="contact-form-verification-grid-item">
                                                    <ContactRegistrationTextbox
                                                        control={popupcontrol}
                                                        placeHolder={language.translateLabel('91c88f05-b534-4048-a7c2-5a1c8c9ad374', 'Company Name', 'Label')}
                                                        label={language.translateLabel('91c88f05-b534-4048-a7c2-5a1c8c9ad374', 'Company Name', 'Label')}
                                                        rules={{ required: language.translateLabel('54b71d43-6681-40ba-90a5-72b81d85fdf2', 'Required', 'Label') }}
                                                        name="company"
                                                    />
                                                </div>
                                                <div className="contact-form-verification-grid-item">
                                                    <ContactRegistrationTextbox
                                                        control={popupcontrol}
                                                        placeHolder={language.translateLabel('427aa6d9-26fe-4c8c-b99f-fe050329790b', 'Phone Number', 'Label')}
                                                        label={language.translateLabel('427aa6d9-26fe-4c8c-b99f-fe050329790b', 'Phone Number', 'Label')}
                                                        rules={{
                                                            required: language.translateLabel('54b71d43-6681-40ba-90a5-72b81d85fdf2', 'Required', 'Label'),
                                                            pattern: {
                                                                value: /^[0-9-]+$/,
                                                                message: "Phone number must contain only numbers",
                                                            },
                                                        }}

                                                        name="phone"
                                                    />
                                                </div>
                                                <div className="contact-form-verification-grid-item">
                                                    <ContactRegistrationTextbox
                                                        control={popupcontrol}
                                                        placeHolder={language.translateLabel('15b53b85-df5e-46db-a112-10950c5dfe37', 'Email', 'Label')}
                                                        label={language.translateLabel('15b53b85-df5e-46db-a112-10950c5dfe37', 'Email', 'Label')}
                                                        rules={{
                                                            required: language.translateLabel('54b71d43-6681-40ba-90a5-72b81d85fdf2', 'Required', 'Label'),
                                                            pattern: {
                                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                message: "E-mail address is not correctly formatted",
                                                            }
                                                        }}
                                                        name="email"
                                                    />
                                                </div>
                                            </div>

                                            <div className='footerSection'>
                                                <button className='btn btn-secondary btn-modal' onClick={() => { popup.current.close() }}>{language.translateLabel('decea43f-6be7-45a4-b31f-592649c71e89', 'Cancel', 'Button')}</button>
                                                <button className='btn btn-primary btn-modal pull-right' >{language.translateLabel('eb0cd90c-5634-4ee0-9412-f02e5ecb8051', 'Submit', 'Button')}</button>

                                            </div>
                                        </form>
                                        :
                                        <div>
                                            <div className="topSectionCentered"> {getContactSubmissionHeaderResponse()}</div>
                                            <div className='contentSection'>
                                                {getContactSubmissionResponse()}
                                            </div>
                                        </div>
                                    }
                                </Popup>
                            </>
                    }
                </AuthenticatedTemplate>
            }
        </div>
    )
};
