import { useNavigate } from 'react-router-dom';
import { useAccount, useMsal } from "@azure/msal-react";

import { loginRequest, protectedResources } from "../authConfig";
import { SecureApiDataAccess } from "./SecureApiDataAccess";

export function useAuthContext() {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const userDataAccess = new SecureApiDataAccess("/api/user");
    const navigate = useNavigate();

    const getLoggedUser = async () => {

        const response = await instance.acquireTokenSilent({
            scopes: [...protectedResources.shopApp.scopes],
            ...loginRequest,
            account: account,
        });

        if (!response.accessToken) {
            throw new Error("Could not retrieve access token.");
        }

        var user = await userDataAccess.get(
            `/search?emailAddress=${encodeURIComponent(
                account.username
            )}&identityProviderAccountId=${encodeURIComponent(account.idTokenClaims.oid)}`,
            response.accessToken,
            {
                throwOnNonSuccess: false
            }
        );

        if (user.hasSuccess) {
            console.log("A user was found", user);
            return user.data;

        } else {
            console.log("Didn't find a user.", user.statusCode);
            return null;
        }
    }

    const openLoginPopup = (redirectUri, state) => {
            instance.loginPopup({
                scopes: loginRequest.scopes,
                redirectUri: redirectUri,
                state: state
            }).then((loginResponse) => {
                if (loginResponse) {
                    navigate(loginResponse.state, {
                        replace: true,
                    });
                }
            }).catch((error) => console.log("login redirect error", error));
    }

    const openLoginPage = (redirectUri, state) => {
            instance.loginRedirect({
                scopes: loginRequest.scopes,
                redirectUri: redirectUri,
                state: state
            }).catch((error) => console.log(error));
    }

    return {
        getLoggedUser,
        openLoginPopup,
        openLoginPage
    }
}
