import React, { useEffect, useRef, useContext, useState } from 'react';
import { DropdownList } from '../core/DropdownList';
import { SettingsContext } from '../infrastructure/Contexts';
import { SecureApiDataAccess } from "../infrastructure/SecureApiDataAccess";
import { UnauthenticatedTemplate, AuthenticatedTemplate, useMsal, useAccount } from "@azure/msal-react";
import { MyAccountPageHeaderOptions } from '../core/MyAccountPageHeaderOptions'
import { loginRequest, protectedResources } from "../authConfig";

import './Home.css';

export const HomeNew = () => {
    const settings = useContext(SettingsContext);
    const { instance, accounts } = useMsal();
    const [salesforceAccountId, setSalesforceAccountId] = useState(null);
    const account = useAccount(accounts[0] || {});
    const [loaded, setLoaded] = useState(false);
    const userDataAccess = new SecureApiDataAccess("/api/user");

    useEffect(() => {

        if (!account) {
           
        }
        const init = async () => {
            var user = await getUser();
            if (user.hasSuccess === true) {
                setLoaded(true);
            }
        }
        init();
    }, [account]);


    const getRegions = () => {
        var result = [];
        settings.regions.forEach(item => {
            var region = {
                id: item.regionCulture.cultureString,
                name: item.regionCulture.name
            };
            result.push(region);
        });
        return result;
    }


    const handleLogin = () => {
        instance.loginRedirect({
            scopes: loginRequest.scopes,
            redirectUri: "/myorders",
        })
            .catch((error) => console.log(error));
    };
    const getUser = async () => {
        const response = await instance.acquireTokenSilent({
            scopes: [...protectedResources.shopApp.scopes],
            ...loginRequest,
            account: account,
        });

        if (!response.accessToken) {
            throw new Error("Could not retrieve access token.");
        }
        var user = await userDataAccess.get(
            `/search?emailAddress=${encodeURIComponent(
                account.username
            )}&identityProviderAccountId=${encodeURIComponent(account.idTokenClaims.oid)}`,
            response.accessToken,
            {
                throwOnNonSuccess: false
            }
        );

        if (user.hasSuccess) {
            console.log("A user was found", user);
            setSalesforceAccountId(user.data.salesforceAccountId);
            return user;

        } else {

            console.log("Didn't find a user.", user.statusCode);
            return null;
        }
    }

    return (
        <div>

            <UnauthenticatedTemplate>
                <h1>Welcome! Please Log in</h1>
                <button className='btn btn-primary' onClick={() => { handleLogin(); }}>Login</button>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <h1>Welcome back!</h1>
                <p>Navigate to the following...</p>
                <MyAccountPageHeaderOptions salesforceAccountId={salesforceAccountId}  />
                <div className="contact-pop-over">

                </div>
            </AuthenticatedTemplate>
        </div>
    )
}


