import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { Searchbox } from '../core/Searchbox';
import { TileContainer, ImageTile } from '../core/ImageTile';
import { Page } from '../core/Page';
import { Tabs, Tab } from '../core/Tabs';
import { TabContent, ActiveTab } from '../components/TabContent';
import { Language } from '../infrastructure/Languages';
import { StaticData } from '../infrastructure/DataContextProvider';
import { AlertTriangle } from 'react-feather'
import { Screen } from '../infrastructure/Screen';
import { useUserContext } from '../infrastructure/UserContext';
import { useTracking } from "react-tracking";
import { useCheckoutContext } from '../infrastructure/CheckoutContext';
import { useCookies } from 'react-cookie';
import { NotFound } from '../pages/NotFound';

import './Search.css';

export const SearchPage = () => {

    const language = useContext(Language);
    const staticData = useContext(StaticData);
    const { search, cat } = useParams();
    const { updateProduct, updateConvertingMethod, updateDimensions, updateMaterial, updateFeature, updateSkuCount, updateCheckoutFlag, product } = useCheckoutContext();
    const { userRegion } = useUserContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchResults, setSearchResults] = useState(null);
    const { trackEvent } = useTracking({ page: "SearchPage" });
    const [cookies, setCookie] = useCookies(['visitorId']);
    const [visitorId, setVisitorId] = useState(null);

    useEffect(() => {
        var searchTerm = getSearchTerm();
        performSearch(searchTerm);
        Screen.scrollToElement('layout');

        var id = cookies.visitorId;
        if (id) {
            setVisitorId(id);
        }
    }, [location.pathname]);


    const getSearchTerm = () => {
        var pathParams = location.pathname.split('/');
        return pathParams && pathParams.length === 4
            ? decodeURI(pathParams[3])
            : null;
    }

    const performSearch = term => {
        setSearchResults(null);
        if (term) {
            try {
                var results = [];
                var items = language.searchPhrase(term, 'Product');
                var products = staticData.getProducts();

                if (items && items.length > 0) {
                    let keyVariants = items.map(i => i.dataObject.keyVariant);
                    keyVariants.forEach(keyVariant => {
                        var product = products.find(p => p.key === keyVariant);
                        results.push(product);
                    });
                    setSearchResults(results);

                    trackEvent({
                        event: 'ordering_tool_interactions',
                        eventProps: {
                            'step': 'order_shopping_0',        // order_shopping_0, order_shopping_0.5
                            'selection_type': 'search',        // selection, search
                            'search_term': term,        // search term entered
                        },
                        userId: visitorId
                    });
                }
                else {
                    trackEvent({
                        event: 'ordering_tool_null_search',
                        eventProps: {
                            'step': 'order_shopping_0',        // order_shopping_0
                            'search_term': term,        // search term entered
                        },
                        userId: visitorId
                    });
                    navigate(`/${userRegion}/noresults`, { replace: true });
                }
            }
            catch {
                setSearchResults(null);
                navigate(`/${userRegion}/search`, { replace: true });
            }
        }
    }


    const onSubmit = term => {
        navigate(`/${userRegion}/search/${term}`);
    }

    const onItemSelected = item => {

        if (product && product.productId !== item.productId) {
            updateConvertingMethod(null);
            updateDimensions(null);
            updateMaterial(null);
            updateFeature(null);
            updateSkuCount(null);
            updateCheckoutFlag(false);
        }

        var model = {
            key: item.key,
            productId: item.productId,
            productName: item.name,
            productImage: item.imageUrl,
            productGroupId: item.productGroup.id,
            industryName: item.industry.value,
            industryCategoryName: item.industryCategory.value,
            url: `/search/${encodeURIComponent(search)}`
        };

        updateProduct(model);

        trackEvent({
            event: 'ordering_tool_selections',
            eventProps: {
                'step': 'order_shopping_0.5',        // order_shopping_0, order_shopping_0.5
                'product_category': item.industry.value,        // e.g. Food & Beverage
                'product_sub_category': item.name, // E.g. Dry Mix
                'selection_type': 'selection',        // selection, search
                'selection': item.productGroup.id,        // E.g. Food & Beverage, Healthcare, Manufacturing
                'search_term': getSearchTerm(),        // search term entered
            },
            userId: visitorId
        });

        navigate(`/${userRegion}/group/${item.productGroup.id}/convertingMethod`);
    }


    return (
        //We are no longer using the shopping experince
        <NotFound />

        /*
        <Page className='search' onRenderHeader={() => {
            return (
                <>
                    {product &&
                        <Tabs>
                            <Tab title={language.translateLabel('04ea9f38-1c48-4a34-9f44-ebbefbcc6436', 'Your Selections', 'Tab')}><TabContent activeTab={ActiveTab.Product} /></Tab>
                        </Tabs>
                    }
                </>

            )
        }} >

            <h2>{language.translateLabel('4d587c25-a50f-490f-9ec4-5d8d5c1e35f5', 'What are you going to be packaging?')}</h2>

            <Searchbox value={getSearchTerm()} onSubmit={onSubmit} />
            {searchResults &&
                <TileContainer>
                    {searchResults.map((item, idx) => {
                        return (
                            <ImageTile index={idx} className={product && product.key == item.key ? 'active-border' : ''} key={`item${idx}`} value={language.translateDomain('c5ac7c91-98da-42e4-bfe7-9b49d00e2b86', item.key, item.name, 'Product')} src={item.imageUrl} onClick={() => onItemSelected(item)} />
                        )
                    })}
                </TileContainer>
            }
        </Page>
        */
    )
}


export const CategoryPage = ({ noSearchResults }) => {

    const { trackEvent } = useTracking();
    const language = useContext(Language);
    const { search, cat } = useParams();
    const { userRegion } = useUserContext();
    const navigate = useNavigate();
    const location = useLocation();
    const productsDataAccess = new ApiDataAccess('/api/products');
    const [searchResults, setSearchResults] = useState(null);
    const [category, setCategory] = useState(null);
    const { updateProduct, updateConvertingMethod, updateDimensions, updateMaterial, updateFeature, updateSkuCount, updateCheckoutFlag, product } = useCheckoutContext();
    const staticData = useContext(StaticData);
    const [cookies, setCookie] = useCookies(['visitorId']);
    const [visitorId, setVisitorId] = useState(null);

    useEffect(() => {
        var searchTerm = getSearchTerm();
        performSearch(searchTerm);

        var id = cookies.visitorId;
        if (id) {
            setVisitorId(id);
        }
    }, [location.pathname]);

    const performSearch = searchTerm => {
        setCategory(null);

        var items = staticData.getCategories();
        var result = [];

        if (searchTerm) {
            var categoryItem = items.find(e => e.industry.value == decodeURIComponent(searchTerm));
            categoryItem.products.forEach(item => {
                if (!item.isSynonym) {
                    result.push(mapToTileItem(item.productGroup, item.name, item.imageUrl, 'Product', item.productId, item.industry.value, item.industryCategory.value, categoryItem.release.releaseId, item.key));
                }
            });
        }
        else {
            items.forEach(item => {
                result.push(mapToTileItem(item.industry, item.industry.value, item.products[0].imageUrl, 'Category', item.products[0].productId, item.industry.value, null, item.release.releaseId, item.products[0].key));
            });
        }
        setSearchResults(result);
    }

    const mapToTileItem = (productGroup, name, imagePath, type, productId, industryName, industryCategoryName, releaseId, key) => {
        return {
            productGroupId: productGroup.id,
            productGroupName: productGroup.value,
            name: name,
            image: imagePath,
            type: type,
            productId: productId,
            industryName: industryName,
            industryCategoryName: industryCategoryName,
            releaseId: releaseId,
            key: key
        }
    }


    const getSearchTerm = () => {
        var pathParams = location.pathname.split('/');
        return pathParams && pathParams.length === 4
            ? decodeURIComponent(pathParams[3])
            : null;
    }


    const onClick = (item) => {
        //console.log("ITEM: ", item)

        if (item.type === 'Category') {

            trackEvent({
                event: 'ordering_tool_interactions',
                eventProps: {
                    'step': 'order_shopping_0',        // order_shopping_0, order_shopping_0.5
                    'selection_type': 'selection',        // selection, search
                    'selection': item.industryName,    // E.g. Food & Beverage, Healthcare, Manufacturing
                    'product_category': item.industryName,        // e.g. Food & Beverage
                    'product_sub_category': item.industryCategoryName // E.g. Dry Mix
                },
                userId: visitorId
            });

            trackEvent({
                event: 'ordering_tool_selections',
                eventProps: {
                    'step': 'order_shopping_0',        // order_shopping_0, order_shopping_0.5
                    'selection_type': 'selection',        // selection, search
                    'selection': item.industryName,    // E.g. Food & Beverage, Healthcare, Manufacturing
                    'product_category': item.industryName,        // e.g. Food & Beverage
                    'product_sub_category': item.industryCategoryName // E.g. Dry Mix
                },
                userId: visitorId
            });


            navigate(`/${userRegion}/categories/${item.name}`);
        }
        else {

            if (product && product.productId !== item.productId) {
                updateConvertingMethod(null);
                updateDimensions(null);
                updateMaterial(null);
                updateFeature(null);
                updateSkuCount(null);
                updateCheckoutFlag(false);
            }

            var model = {
                key: item.key,
                productId: item.productId,
                productName: item.name,
                productImage: item.image,
                productGroupId: item.productGroupId,
                industryName: item.industryName,
                industryCategoryName: item.industryCategoryName,
                url: `/categories/${encodeURIComponent(cat)}`
            };

            updateProduct(model);

            trackEvent({
                event: 'ordering_tool_interactions',
                eventProps: {
                    'step': 'order_shopping_0.5',        // order_shopping_0, order_shopping_0.5
                    'product_category': item.industryName,        // e.g. Food & Beverage
                    'product_sub_category': item.industryCategoryName, // E.g. Dry Mix
                    'selection_type': 'selection',        // selection, search
                    'selection': item.productId,        // E.g. Food & Beverage, Healthcare, Manufacturing
                },
                userId: visitorId
            });
            trackEvent({
                event: 'ordering_tool_selections',
                eventProps: {
                    'step': 'order_shopping_0.5',        // order_shopping_0, order_shopping_0.5
                    'product_category': item.industryName,        // e.g. Food & Beverage
                    'product_sub_category': item.industryCategoryName, // E.g. Dry Mix
                    'selection_type': 'selection',        // selection, search
                    'selection': item.productId,        // E.g. Food & Beverage, Healthcare, Manufacturing
                },
                userId: visitorId
            });

            navigate(`/${userRegion}/group/${item.productGroupId}/convertingMethod`);
        }
    }

    const onSubmit = term => {

        navigate(`/${userRegion}/search/${term}`);
    }

    const getTranslation = item => {
        if (item.type == 'Product') {
            return language.translateDomain('c5ac7c91-98da-42e4-bfe7-9b49d00e2b86', item.key, item.name, 'Product', item);
        }
        else {
            return language.translateDomain('5c838e14-407a-483d-909c-accf69c19ee7', item.productGroupId, item.name, 'Category');
        }
    }


    return (
        //We are no longer using the shopping experince
        <NotFound />

        /*
        <Page className='search' onRenderHeader={() => {
            return (
                <>
                    {product &&
                        <Tabs>
                            <Tab title={language.translateLabel('04ea9f38-1c48-4a34-9f44-ebbefbcc6436', 'Your Selections', 'Tab')}><TabContent activeTab={ActiveTab.Product} /></Tab>
                        </Tabs>
                    }
                </>
            )
        }} >

            <h2>{language.translateLabel('4d587c25-a50f-490f-9ec4-5d8d5c1e35f5', 'What are you going to be packaging?')}</h2>

            <Searchbox onSubmit={onSubmit} />

            {noSearchResults &&
                <div className="alert info">
                    <div className="alert-content"><AlertTriangle className='icon' /> <span>{language.translateLabel('51aac7a8-3a0d-4b73-9350-f44fd7a3a611', 'We could not find any matching products. Please select one of the categories below:', 'Label')}</span></div>
                </div>
            }

            {searchResults &&
                <TileContainer>
                    {searchResults.map((item, idx) => {
                        return (
                            <div key={`category${idx}`}>

                                {item.type == 'Product'
                                    ? <ImageTile index={idx} className={product && product.key == item.key ? 'active-border' : ''} value={getTranslation(item)} src={item.image} onClick={() => { onClick(item) }} />
                                    : <ImageTile index={idx} value={getTranslation(item)} src={language.imageUrl('7f908a65-bbd1-4569-8107-c17e5c4e377d', item.productGroupId, 'Industry Category', item.name)} onClick={() => { onClick(item) }} />
                                }
                            </div>
                        )
                    })}
                </TileContainer>
            }
        </Page>
        */
    )
}
