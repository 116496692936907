import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Popup } from '../../core/Popup';
import { Translation } from "../../core/Translation";
import { ApiDataAccess } from "../../infrastructure/ApiDataAccess";
import { ReorderSkuSelectionComponent } from "../../components/reorder/ReorderSkuSelectionComponent";


export const ReorderSkuSelectionUnauthenticated = () => {

    let { reorderIntent } = useParams();

    const popup = useRef(null);
    const navigate = useNavigate();

    const [orderDetails, setOrderDetails] = useState();

    useEffect(() => {
        const init = async () => {
            await loadConfig();
        }

        if (!!reorderIntent)
            init();

    }, []);

    const loadConfig = async () => {
        try {
            var apiDataAccess = new ApiDataAccess('/api/reorder/');
            var response = await apiDataAccess.get(`/intent/${reorderIntent}/configuration`);
            console.log("Data", response);
            setOrderDetails(response);
        }
        catch (ex) {
            popup.current.show();
        }
    }

    const goToQuantitySelectionPage = async (selectedSkus) => {
        navigate("quantity", {
            state:
            {
                selectedSkus: selectedSkus
            }
        });
    }

    return (
        <div>
            {
                orderDetails &&
                <ReorderSkuSelectionComponent orderDetails={orderDetails} onSubmit={(selectedSkus) => { goToQuantitySelectionPage(selectedSkus) }} />   
            }
            <Popup closeOnClickAway={true} onClose={() => { }} ref={popup}>
                <div className='contentSection'>
                    <Translation id='9b96a5c1-497a-4211-962d-d5c58a91c943' defaultMessage='Reorder session has expired' category='Label' />
                </div>
            </Popup>
        </div>
    )
}