import { useParams } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { MyAccountRouter } from "./MyAccountRouter";
import { Login } from "./Login";

export const MyAccountLandingPage = () => {

    let { region } = useParams();

    return (
        <div>
            <AuthenticatedTemplate>
                <MyAccountRouter region={region} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login redirectUri="/myaccount/route" state={region} />
            </UnauthenticatedTemplate>
        </div>
    )
}