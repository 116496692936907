import React, { useState, useContext } from "react";
import { ShoppingBreadcrumbs, OrderReference } from '../../core/Breadcrumbs';
import { FormatProvider } from "../../core/offer/FormatProvider";
import { Translation } from "../../core/Translation";
import { Money } from "../../components/offer/Money";
import { ShippingAddressesDisplay } from "../../components/offer/ShippingAddressDisplay";
import { Card } from "../../core/Card";
import { useGlobalOfferContext, } from "../../infrastructure/GlobalOfferId";
import { useEffect } from "react";
import OrderPlacementApi from "../../infrastructure/OrderPlacementApi";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CapabilitiesContext } from "../../infrastructure/Contexts";
import { CapabilityName } from "../../infrastructure/Constants";
import { ContextHelp } from "../../core/ContextHelp";

import "./ReviewOrder.css";
import { useTracking } from "react-tracking";

export const ReviewOrder = ({ globalOfferId, orderReference, analyticsMetadata, offer, termsConditionUrl, orderConfiguration }) => {

    const { trackEvent } = useTracking({ page: "Order Review" });
    const navigate = useNavigate();
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const orderPlacementApi = new OrderPlacementApi("/");
    const paymentsDisabledOnRegion = orderConfiguration ? orderConfiguration.paymentsDisabled : false;

    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [orderIntentTotals, setOrderIntentTotals] = useState(null);
    const [orderApiBalanceData, setOrderApiBalanceData] = useState(null);

    const {
        formState: { errors, isValid, isSubmitting },
        reset,
        handleSubmit,
        register,
    } = useForm({
        defaultValues: {
            userAgreedToTerms: false,
        },
    });

    const {
        offerContext,
        loadOfferContextStatus,
        saveContext,
        submitCheckoutIntent,
        getEstimatedAmountDue
    } = useGlobalOfferContext(globalOfferId);

    useEffect(() => {
        isSubmitting ? loader.show() : loader.hide();
    }, [isSubmitting]);

    /*Clean up the lingering modal until it can be slightly refactored to unload correctly */
    useEffect(() => {
        return () => {
            loader.hide();
        };
    }, []);

    useEffect(() => {
        const init = async () => {
            if (loadOfferContextStatus === "success") {
                reset({
                    userAgreedToTerms: offerContext && offerContext?.checkoutMetadata?.userAgreedToTerms,
                });
                console.log("feature flag requote, user agreed to terms", process.env.REACT_APP_ORDER_PLACEMENT_ALLOW_REQUOTE, offerContext?.checkoutMetadata?.userAgreedToTerms);
                await loadOrderApiAmountDue();
            }
        }
        init();
    }, [offerContext]);

    const testRoll = offer?.testRollType ? offer?.testRollType : null;

    const onAgreedClick = async (agreed, amount, totals) => {

        if (agreed) {
            setOrderIntentTotals(totals);
            setAgreedToTerms(true);

            trackEvent({
                event: "ordering_tool_selections",
                eventProps: {
                    step: "placement_3",
                    product_category: analyticsMetadata.industryName,
                    product_sub_category:
                        analyticsMetadata.industryCategoryName,
                    element: 'terms_and_conditions',
                    selection: true
                },
                userId: analyticsMetadata?.visitorId,
            });
        }
    };

    const onSubmit = async (data, errors) => {
        console.log(data, errors);

        await saveContext({
            orderIntentTotals: orderIntentTotals,
            checkoutMetadata: {
                userAgreedToTerms: agreedToTerms
            },
            salesforceAccountName: orderReference.customerName
        });

        var response = await submitCheckoutIntent();

        console.log("checkout intent response", response);
        if (response.hasSuccess) {
            trackEvent({
                event: "ordering_tool_interactions",
                eventProps: {
                    step: "placement_3",
                    product_category: analyticsMetadata.industryName,
                    product_sub_category:
                        analyticsMetadata.industryCategoryName,
                    element: 'submit'
                },
                userId: analyticsMetadata?.visitorId,
            });

            //refresh();
            navigate(`/offer/${globalOfferId}/todo`, {
                replace: true
            });
        }
    };

    const loadOrderApiAmountDue = async () => {

        var skus = [];
        offerContext.skuLineItems.forEach((sku) => {
            skus.push({
                unitPrice: sku.unitPrice,
                quantity: sku.quantity
            })
        })

        var response = await getEstimatedAmountDue(globalOfferId, {
            skuLineItems: skus,
            customerPicksUpAtPlant: offerContext.plantPickup
        });

        if (response != null) {
            setOrderApiBalanceData(response);
        }
    }

    const renderView = () => {
        if (testRoll) {
            return (<TestRollOrderTotalsDisplay
                skus={offerContext.skuLineItems}
                offer={offer}
                plantPickup={offerContext.plantPickup}
                register={register}
                errors={errors}
                onAgreed={(value, amount, orderIntentTotals) => {
                    onAgreedClick(value, amount, orderIntentTotals);
                }}
                orderPlacementApi={orderPlacementApi}
                offerKey={globalOfferId}
                termsConditionUrl={termsConditionUrl}
                paymentsDisabledOnRegion={paymentsDisabledOnRegion}
            />);
        }

        if (process.env.REACT_APP_ORDER_PLACEMENT_ALLOW_REQUOTE === 'true')
            return (<Card>
                {orderApiBalanceData && orderApiBalanceData.hasSuccess && <OrderApiOrderTotalsDisplay
                    orderApiBalanceData={orderApiBalanceData.response}
                    skus={offerContext.skuLineItems}
                    offer={offer}
                    plantPickup={offerContext.plantPickup}
                    register={register}
                    errors={errors}
                    onAgreed={(value, amount, orderIntentTotals) => {
                        onAgreedClick(value, amount, orderIntentTotals);
                    }}
                    orderPlacementApi={orderPlacementApi}
                    offerKey={globalOfferId}
                    termsConditionUrl={termsConditionUrl}
                    paymentsDisabledOnRegion={paymentsDisabledOnRegion}
                />}
            </Card>);

        return (<OrderTotalsDisplayNew
            skus={offerContext.skuLineItems}
            offer={offer}
            plantPickup={offerContext.plantPickup}
            register={register}
            errors={errors}
            onAgreed={(value, amount, orderIntentTotals) => {
                onAgreedClick(value, amount, orderIntentTotals);
            }}
            orderPlacementApi={orderPlacementApi}
            offerKey={globalOfferId}
            termsConditionUrl={termsConditionUrl}
            paymentsDisabledOnRegion={paymentsDisabledOnRegion}
        />);
    }

    return (
        offerContext &&
        offer && (
            <div>
                <ShoppingBreadcrumbs active={2} paymentsDisabled={paymentsDisabledOnRegion} />
                <OrderReference orderReference={orderReference} />

                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <ShippingAddressDisplay address={offerContext.shippingAddress} poNumber={offerContext.poNumber} poFile={props.poFile} /> */}

                    <ShippingAddressesDisplay
                        address={offerContext.shippingAddress}
                        additionalShippingAddresses={offerContext.additionalShippingAddresses}
                        poNumber={offerContext.poNumber}
                        globalOfferId={globalOfferId}
                        purchaseOrderUploadAttachmentId={offerContext.checkoutMetadata?.purchaseOrderUploadAttachmentId} />
                    {/* {testRoll == null ?
                        offer.calculationBreakdown && offer.calculationBreakdown[0].initiallyDue ? (
                            <OrderTotalsDisplayNew
                                skus={offerContext.skuLineItems}
                                offer={offer}
                                plantPickup={offerContext.plantPickup}
                                register={register}
                                errors={errors}
                                onAgreed={(value, amount, orderIntentTotals) => {
                                    onAgreedClick(value, amount, orderIntentTotals);
                                }}
                                orderPlacementApi={orderPlacementApi}
                                offerKey={globalOfferId}
                                termsConditionUrl={termsConditionUrl}
                            />
                        ) : (
                            <OrderTotalsDisplay
                                skus={offerContext.skuLineItems}
                                offer={offer}
                                plantPickup={offerContext.plantPickup}
                                onAgreed={(value, amount, orderIntentTotals) => {
                                    onAgreedClick(value, amount, orderIntentTotals);
                                }}
                                orderPlacementApi={orderPlacementApi}
                                offerKey={globalOfferId}
                                termsConditionUrl={termsConditionUrl}
                            />
                        )

                        :
                        <TestRollOrderTotalsDisplay
                            skus={offerContext.skuLineItems}
                            offer={offer}
                            plantPickup={offerContext.plantPickup}
                            register={register}
                            errors={errors}
                            onAgreed={(value, amount, orderIntentTotals) => {
                                onAgreedClick(value, amount, orderIntentTotals);
                            }}
                            orderPlacementApi={orderPlacementApi}
                            offerKey={globalOfferId}
                            termsConditionUrl={termsConditionUrl}
                        />
                    }

                    {process.env.REACT_APP_ORDER_PLACEMENT_ALLOW_REQUOTE === 'true' ?
                        <div className="debug-card">
                        <Card>
                            <div>
                                DEBUG: Order Api Balance data
                                <div></div>
                                <div>
                                <button
                                    className="btn btn-primary btn-small"
                                    disabled={fetchingDebugData}
                                    onClick={() => {
                                            loadOrderApiAmountDue();
                                        }}>
                                            {fetchingDebugData ? "Fetching" : "Fetch"}
                                    </button>
                                </div>
                                {orderApiBalanceData && orderApiBalanceData.hasSuccess && !fetchingDebugData && <OrderApiOrderTotalsDisplay
                                    orderApiBalanceData={orderApiBalanceData.response}
                                    skus={offerContext.skuLineItems}
                                    offer={offer}
                                    plantPickup={offerContext.plantPickup}
                                    register={register}
                                    errors={errors}
                                    onAgreed={(value, amount, orderIntentTotals) => {
                                        onAgreedClick(value, amount, orderIntentTotals);
                                    }}
                                    orderPlacementApi={orderPlacementApi}
                                    offerKey={globalOfferId}
                                    termsConditionUrl={termsConditionUrl}
                                />}
                            </div>
                            <div>
                            </div>
                            </Card>
                        </div>
                        : null
                    } */}
                    {renderView()}

                    <button
                        className="btn btn-tertiary-outline back"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(`/offer/${globalOfferId}/skus`);
                        }}
                    ><span className='btn-text'><Translation id='03473ff0-be47-4bd3-9ed8-190fedf344c5' defaultMessage='Back' category='Button' /></span>
                    </button>

                    <div className="pull-right">
                        <button
                            data-testid="order-placement-review-order-submit"
                            className="btn btn-primary"
                            disabled={!isValid && !isSubmitting}
                            onClick={() => {
                                handleSubmit(async (data, errors) => await onSubmit(data, errors));
                            }}
                        >
                            Next
                        </button>
                    </div>
                </form>
            </div>
        )
    );
};

export const SkuDisplay = ({ skus, currency }) => {
    const getUnitPrice = (amount) => {
        return parseFloat(amount.toFixed(4));
    };

    const getSkuSubTotaAmount = (unitPrice, quantity) => {
        let total = getUnitPrice(unitPrice) * quantity;
        return parseFloat(total.toFixed(2));
    };
    return (
        <Card
            title={
                <Translation
                    id="0e955704-3aa3-4975-b9f5-f4e958bacad3"
                    defaultMessage="SKU Description"
                    category="Card Title"
                />
            }
        >
            <div className="skus display">
                <table>
                    <thead>
                        <tr>
                            <th className="description"></th>
                            <th className="unitPrice">
                                <Translation
                                    id="5e4f786d-029d-4464-8911-dcb3db5bab93"
                                    defaultMessage="Unit Price"
                                    category="Label"
                                />
                            </th>
                            <th className="quantity">
                                <Translation
                                    id="6c6df362-bf55-4248-8e0a-4af3eb5c99d4"
                                    defaultMessage="Quantity"
                                    category="Label"
                                />
                            </th>
                            <th className="totalPrice">
                                <Translation
                                    id="1c121194-9a5c-4815-b707-43d16469423d"
                                    defaultMessage="Total Price"
                                    category="Label"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {skus.map((sku, idx) => {
                            return (
                                <tr key={`sku${idx}`}>
                                    <td className="description">{sku.description}</td>
                                    <td className="unitPrice">
                                        <Money currency={currency} amount={sku.unitPrice.toFixed(4)} />
                                    </td>
                                    <td className="quantity">{FormatProvider.FormatNumberWithCommas(sku.quantity.toFixed(2))}</td>
                                    <td className="totalPrice">
                                        <Money
                                            currency={currency}
                                            amount={FormatProvider.FormatDecimalWithCommas(getSkuSubTotaAmount(sku.unitPrice, sku.quantity).toFixed(2), 2)}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Card>
    );
};

export const TotalsDisplay = ({ currency, orderApiBalanceData, onAgreed, register, errors, termsConditionUrl, plantPickup, paymentsDisabledOnRegion }) => {

    const onCheckBoxChanged = (value, valid) => {
        var grandTotalAmount = orderApiBalanceData.paymentDueAmount.amount;
        let orderIntentTotals = {
            grandTotalAmount: orderApiBalanceData.grandTotalAmount.amount,
            shippingAmount: orderApiBalanceData.shippingAmount.amount,
            overageAmount: orderApiBalanceData.overageAmount.amount,
            epacConnectAmount: orderApiBalanceData.epacConnectAmount.amount,
            partialPaymentAmount: orderApiBalanceData.paymentDueAmount.amount,
            estimatedShippingAmount: orderApiBalanceData.futureProductOverageAmount.amount,
            estimatedOverageAmount: orderApiBalanceData.futureProductOverageAmount.amount,
            subTotalAmount: orderApiBalanceData.subTotalAmount.amount
        };
        console.log("agreed (Order Api)", orderIntentTotals);
        if (onAgreed) {
            onAgreed(value, grandTotalAmount, orderIntentTotals);
        }
    };

    const formatCurrency = (value) => {
        return FormatProvider.FormatDecimalWithCommas(value.toFixed(2), 2);
    };

    const formatCurrencyWithAccountingNotation = (value) => {
        var rounded = value.toFixed(2);

        if (rounded < 0) {
            return `(${FormatProvider.FormatDecimalWithCommas(Math.abs(rounded), 2)})`;
        }
        return FormatProvider.FormatDecimalWithCommas(rounded, 2);;
    }

    return (
        <Card transparent={true}>
            <div className="skus display grandTotal">
                <table>
                    <tbody>
                        {(orderApiBalanceData.subTotalAmount.amount != orderApiBalanceData.grandTotalAmount.amount || paymentsDisabledOnRegion) && (
                            <tr>
                                <td></td>
                                <td className="name">
                                    <Translation
                                        id="8bb80a73-14d0-4014-96b8-f3c0a73135a5"
                                        defaultMessage="Subtotal"
                                        category="Label"
                                    />
                                </td>
                                <td className="totalPrice">
                                    <Money currency={currency} amount={formatCurrency(orderApiBalanceData.subTotalAmount.amount)} />
                                </td>
                            </tr>
                        )}
                        {
                            paymentsDisabledOnRegion ?
                                <div className="payment-disabled-warning">
                                    <i>
                                        <Translation id='c7e48e68-12b5-439d-b32b-f43be33bc605' defaultMessage='Overage, Shipping, and VAT are not included in the Subtotal. A Prepayment request will be sent after artwork upload' category='Label' />
                                    </i>
                                </div>
                                :
                                <>
                                    {
                                        orderApiBalanceData.epacConnectAmount.amount > 0 && (
                                            <tr>
                                                <td></td>
                                                <td className="name">
                                                    <Translation
                                                        id="4c2c9b2d-92d6-402c-8117-4812e417e6d7"
                                                        defaultMessage="ePac Connect"
                                                        category="Label"
                                                    />
                                                    <ContextHelp context={"order-summary-connect"} />
                                                </td>
                                                <td className="totalPrice">
                                                    <Money currency={currency} amount={formatCurrency(orderApiBalanceData.epacConnectAmount.amount)} />
                                                </td>
                                            </tr>
                                        )
                                    }

                                    {
                                        orderApiBalanceData.overageAmount.amount > 0 && (
                                            <tr>
                                                <td></td>
                                                <td className="name">
                                                    <Translation
                                                        id="ede6f5db-ad17-4dfa-b60a-6c48e656f515"
                                                        defaultMessage="Overage"
                                                        category="Label"
                                                    />
                                                    <ContextHelp context={"order-summary-overage"} />
                                                </td>
                                                <td className="totalPrice">
                                                    <Money currency={currency} amount={formatCurrency(orderApiBalanceData.overageAmount.amount)} />
                                                </td>
                                            </tr>
                                        )
                                    }

                                    {
                                        orderApiBalanceData.shippingAmount.amount > 0 && (
                                            <tr>
                                                <td></td>
                                                <td className="name">
                                                    <Translation
                                                        id="b0bb9d47-0aca-4f28-9a5e-6c159a12fdb9"
                                                        defaultMessage="Shipping"
                                                        category="Label"
                                                    />
                                                    <ContextHelp context={"order-summary-shipping"} />
                                                </td>
                                                <td className="totalPrice">
                                                    <Money currency={currency} amount={orderApiBalanceData.shippingAmount.amount.toFixed(2)} />
                                                </td>
                                            </tr>
                                        )
                                    }

                                    <tr>
                                        <td></td>
                                        <td className="name">
                                            <Translation
                                                id="ba14389f-2999-4067-9244-04d2fb5932c4"
                                                defaultMessage="Total Amount"
                                                category="Label"
                                            />
                                        </td>
                                        <td className="totalPrice">
                                            <Money currency={currency} amount={formatCurrency(orderApiBalanceData.grandTotalAmount.amount)} />
                                        </td>
                                    </tr>

                                    {
                                        orderApiBalanceData.totalAvailableCreditAmount.amount < 0 && (
                                            <tr>
                                                <td></td>
                                                <td className="name">
                                                    <Translation
                                                        id="b6e9a432-7eb5-4201-baff-c8ee0b647555"
                                                        defaultMessage="Credit Applied"
                                                        category="Label"
                                                    />
                                                    <ContextHelp context={"order-applied-credit"} />
                                                </td>
                                                <td className="totalPrice">
                                                    <Money currency={currency} amount={formatCurrencyWithAccountingNotation(orderApiBalanceData.totalAvailableCreditAmount.amount)} />
                                                </td>
                                            </tr>
                                        )
                                    }

                                    {
                                        orderApiBalanceData.refundDueAmount.amount < 0 && (
                                            <tr>
                                                <td></td>
                                                <td className="name">
                                                    <Translation
                                                        id="c61e089f-083f-4bd8-9e83-f170bb105aff"
                                                        defaultMessage="Refund Due"
                                                        category="Label"
                                                    />
                                                </td>
                                                <td className="totalPrice">
                                                    <Money
                                                        currency={currency}
                                                        amount={formatCurrencyWithAccountingNotation(orderApiBalanceData.refundDueAmount.amount)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }

                                    <tr className="color-orange">
                                        <td></td>
                                        <td className="name">
                                            {/*<Translation*/}
                                            {/*    id="c7c4541a-7b83-4ce2-9772-59076c363a5c"*/}
                                            {/*    defaultMessage="Payment Due Now"*/}
                                            {/*    category="Label"*/}
                                            {/*/>*/}
                                            <Translation
                                                id="386f5f4f-3372-4856-a045-3988858cda59"
                                                defaultMessage="Total Amount Due Today"
                                                category="Label"
                                            />
                                        </td>
                                        <td className="totalPrice">
                                            <Money
                                                currency={currency}
                                                amount={formatCurrency(orderApiBalanceData.paymentDueAmount.amount)}
                                            />
                                        </td>
                                    </tr>

                                    {
                                        orderApiBalanceData.futureProductOverageAmount.amount > 0 && (
                                            <tr>
                                                <td></td>
                                                <td className="name">
                                                    <Translation
                                                        id="25552edc-3b23-4017-a4a0-008aaa18c5dc"
                                                        defaultMessage="Estimated Overage"
                                                        category="Label"
                                                    />
                                                </td>
                                                <td className="totalPrice">
                                                    <Money
                                                        currency={currency}
                                                        amount={formatCurrency(orderApiBalanceData.futureProductOverageAmount.amount)}
                                                    />
                                                </td>
                                            </tr>
                                        )}

                                    {
                                        orderApiBalanceData.paymentDueAmount.amount != orderApiBalanceData.grandTotalAmount.amount && orderApiBalanceData.creditAppliedAmount.amount === 0 && !plantPickup && (
                                            <tr>
                                                <td></td>
                                                <td className="name">
                                                    <Translation
                                                        id="54d8d735-093c-4ac1-920b-81c4ee8b7a67"
                                                        defaultMessage="Estimated Shipping"
                                                        category="Label"
                                                    />
                                                </td>
                                                <td className="totalPrice">
                                                    {orderApiBalanceData.futureShippingAmount.amount > 0 ? (
                                                        <Money
                                                            currency={currency}
                                                            amount={formatCurrency(orderApiBalanceData.futureShippingAmount.amount)}
                                                        />
                                                    ) : (
                                                        <span>TBD</span>
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                </>
                        }
                    </tbody>
                </table>
            </div>
            <div className="agree-to-terms">
                <label htmlFor="agreeId" className="controlContainer control checkbox agree-to-terms-align-right">
                    <input
                        type="checkbox"
                        data-testid="order-placement-review-terms-checkbox"
                        id="agreeId"
                        className="checkbox"
                        {...register("userAgreedToTerms", {
                            required: "You must agree to ePac's Terms and Conditions before submitting your order",
                            onChange: (e) => onCheckBoxChanged(e.target.value),
                        })}
                    />
                    <Translation
                        id="3474ccaa-e1da-488f-a1b8-1202c9983883"
                        defaultMessage="By placing this order I agree to:"
                        category="Label"
                    />{" "}
                    <a target="_blank" rel="noopener noreferrer" href={`${termsConditionUrl}`}>
                        <Translation
                            id="d6e8ba98-e88d-4358-a95a-e0a8620a2db5"
                            defaultMessage="ePac's Terms and Conditions"
                            category="Label"
                        />
                    </a>
                </label>
                {errors && errors.agreedToTerms && (
                    <span className="error-message agree-to-terms-align-right">
                        {errors.agreedToTerms.message}
                    </span>
                )}
            </div>
        </Card>
    );
};

export const OrderApiOrderTotalsDisplay = (props) => {

    useEffect(() => {
        console.log(props.orderApiBalanceData);
        console.log("skus", props.skus);
    }, []);

    return props.skus ? (
        <div>
            <SkuDisplay skus={props.skus} currency={props.offer.supportedCountry.currency} />
            <TotalsDisplay
                currency={props.offer.supportedCountry.currency}
                errors={props.errors}
                register={props.register}
                onAgreed={props.onAgreed}
                orderApiBalanceData={props.orderApiBalanceData}
                termsConditionUrl={props.termsConditionUrl}
                plantPickup={props.plantPickup}
                paymentsDisabledOnRegion={props.paymentsDisabledOnRegion}
            />
        </div>
    ) : (
        ""
    );
};

export const OrderTotalsDisplayNew = (props) => {

    var paymentsDisabledOnRegion = props.paymentsDisabledOnRegion;

    const getCurrency = () => {
        return props.offer.supportedCountry.currency;
    };

    const getTotalSkuQuantity = () => {
        let qty = 0;
        if (props.skus) {
            props.skus.forEach((sku) => {
                qty += sku.quantity;
            });
        }
        return qty;
    };

    const getUnitPrice = (amount) => {
        return parseFloat(amount.toFixed(4));
    };

    const getSkuSubTotaAmount = (unitPrice, quantity) => {
        let total = getUnitPrice(unitPrice) * quantity;
        return parseFloat(total.toFixed(2));
    };

    const getCalculationBreakdown = () => {
        let selectedCalc = null;
        let totalOrderQuantity = getTotalSkuQuantity();
        let calcs = props.offer.calculationBreakdown;
        calcs.forEach((calc) => {
            if (calc.quantityBreak <= totalOrderQuantity) {
                selectedCalc = calc;
            }
        });
        return selectedCalc;
    };

    const getSubTotalAmount = () => {
        let result = 0;
        if (props.skus) {
            props.skus.forEach((sku) => {
                result += getSkuSubTotaAmount(sku.unitPrice, sku.quantity);
            });
        }
        return result;
    };

    const getConnectAmount = () => {
        var br = getCalculationBreakdown();
        var percentage = getSkuQuantiyToQuantityBreakPercentage();
        return br.sellingPrice.epacConnect.totalPriceIncludingOverage.amount * percentage;
    };

    const getOverageAmount = () => {
        var br = getCalculationBreakdown();
        var percentage = getSkuQuantiyToQuantityBreakPercentage();
        return (
            (br.initiallyDue.product.totalPriceIncludingOverage.amount - br.initiallyDue.product.totalPrice.amount) *
            percentage
        );
    };

    const getShippingAmount = () => {
        if (!props.plantPickup) {
            var br = getCalculationBreakdown();
            var percentage = getSkuQuantiyToQuantityBreakPercentage();
            return br.initiallyDue.shipping.totalPriceIncludingOverage.amount * percentage;
        } else {
            return 0;
        }
    };

    const getSkuQuantiyToQuantityBreakPercentage = () => {
        var br = getCalculationBreakdown();
        var bqty = br.quantityBreak;
        var sqty = getTotalSkuQuantity();
        return sqty / bqty;
    };

    const getGrandTotalAmount = () => {
        var res = 0;
        res += getSubTotalAmount();
        res += getConnectAmount();
        res += getOverageAmount();
        res += getShippingAmount();
        return roundDown(res, 2);
    };

    const getPartialPaymentAmount = () => {
        var br = getCalculationBreakdown();
        var percentage = getSkuQuantiyToQuantityBreakPercentage();
        var grandTotalAmount = getGrandTotalAmount();
        var amountDue = props.plantPickup
            ? roundDown(
                (br.initiallyDue.paymentAmountDue.amount -
                    br.initiallyDue.shipping.totalPriceIncludingOverage.amount) *
                percentage,
                2
            )
            : roundDown(br.initiallyDue.paymentAmountDue.amount * percentage, 2);

        return Math.abs(grandTotalAmount - amountDue) < 1 ? grandTotalAmount : amountDue;
    };

    const getFutureOverageAmount = () => {
        var br = getCalculationBreakdown();
        var percentage = getSkuQuantiyToQuantityBreakPercentage();
        return roundDown(br.initiallyDue.futureProductOverageAmount.amount * percentage, 2);
    };

    const getFutureShippingAmount = () => {
        if (!props.plantPickup) {
            var br = getCalculationBreakdown();
            var percentage = getSkuQuantiyToQuantityBreakPercentage();
            return roundDown(br.initiallyDue.futureShippingAmount.amount * percentage, 2);
        } else {
            return 0;
        }
    };

    const roundDown = (value, decimals) => {
        return parseFloat(value.toFixed(decimals));
    };

    const onCheckBoxChanged = (value, valid) => {
        var grandTotalAmount = getPartialPaymentAmount();
        let orderIntentTotals = {
            grandTotalAmount: getGrandTotalAmount(),
            shippingAmount: getShippingAmount(),
            overageAmount: getOverageAmount(),
            epacConnectAmount: getConnectAmount(),
            partialPaymentAmount: getPartialPaymentAmount(),
            estimatedShippingAmount: getFutureOverageAmount(),
            estimatedOverageAmount: getFutureOverageAmount(),
            subTotalAmount: getSubTotalAmount()
        };
        console.log("agreed", orderIntentTotals)
        if (props.onAgreed) {
            props.onAgreed(value, grandTotalAmount, orderIntentTotals);
        }
    };

    const formatCurrency = (value) => {
        return FormatProvider.FormatDecimalWithCommas(value.toFixed(2), 2);
    };

    const formatQuantity = (value) => {
        return FormatProvider.FormatNumberWithCommas(value.toFixed(2));
    };

    return props.skus ? (
        <div>
            <Card
                title={
                    <Translation
                        id="0e955704-3aa3-4975-b9f5-f4e958bacad3"
                        defaultMessage="SKU Description"
                        category="Card Title"
                    />
                }
            >
                <div className="skus display">
                    <table>
                        <thead>
                            <tr>
                                <th className="description"></th>
                                <th className="unitPrice">
                                    <Translation
                                        id="5e4f786d-029d-4464-8911-dcb3db5bab93"
                                        defaultMessage="Unit Price"
                                        category="Label"
                                    />
                                </th>
                                <th className="quantity">
                                    <Translation
                                        id="6c6df362-bf55-4248-8e0a-4af3eb5c99d4"
                                        defaultMessage="Quantity"
                                        category="Label"
                                    />
                                </th>
                                <th className="totalPrice">
                                    <Translation
                                        id="1c121194-9a5c-4815-b707-43d16469423d"
                                        defaultMessage="Total Price"
                                        category="Label"
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.skus.map((sku, idx) => {
                                return (
                                    <tr key={`sku${idx}`}>
                                        <td className="description">{sku.description}</td>
                                        <td className="unitPrice">
                                            <Money currency={getCurrency()} amount={sku.unitPrice.toFixed(4)} />
                                        </td>
                                        <td className="quantity">{formatQuantity(sku.quantity)}</td>
                                        <td className="totalPrice">
                                            <Money
                                                currency={getCurrency()}
                                                amount={formatCurrency(
                                                    getSkuSubTotaAmount(sku.unitPrice, sku.quantity)
                                                )}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Card>
            <Card transparent={true}>
                <div className="skus display grandTotal">
                    <table>
                        <tbody>
                            {(getSubTotalAmount() != getGrandTotalAmount() || paymentsDisabledOnRegion) && (
                                <tr>
                                    <td></td>
                                    <td className="name">
                                        <Translation
                                            id="8bb80a73-14d0-4014-96b8-f3c0a73135a5"
                                            defaultMessage="Subtotal"
                                            category="Label"
                                        />
                                    </td>
                                    <td className="totalPrice">
                                        <Money currency={getCurrency()} amount={formatCurrency(getSubTotalAmount())} />
                                    </td>
                                </tr>
                            )}
                            {
                                paymentsDisabledOnRegion ?
                                    <tr>
                                        <td className="payment-disabled-warning">
                                            <i>
                                                <Translation id='c7e48e68-12b5-439d-b32b-f43be33bc605' defaultMessage='Overage, Shipping, and VAT are not included in the Subtotal. A Prepayment request will be sent after artwork upload' category='Label' />
                                            </i>
                                        </td>
                                    </tr>
                                    :
                                    <>
                                        {getConnectAmount() > 0 && (
                                            <tr>
                                                <td></td>
                                                <td className="name">
                                                    <Translation
                                                        id="4c2c9b2d-92d6-402c-8117-4812e417e6d7"
                                                        defaultMessage="ePac Connect"
                                                        category="Label"
                                                    />
                                                    <ContextHelp context={"order-summary-connect"} />
                                                </td>
                                                <td className="totalPrice">
                                                    <Money currency={getCurrency()} amount={formatCurrency(getConnectAmount())} />
                                                </td>
                                            </tr>
                                        )}

                                        {getOverageAmount() > 0 && (
                                            <tr>
                                                <td></td>
                                                <td className="name">
                                                    <Translation
                                                        id="ede6f5db-ad17-4dfa-b60a-6c48e656f515"
                                                        defaultMessage="Overage"
                                                        category="Label"
                                                    />
                                                    <ContextHelp context={"order-summary-overage"} />
                                                </td>
                                                <td className="totalPrice">
                                                    <Money currency={getCurrency()} amount={formatCurrency(getOverageAmount())} />
                                                </td>
                                            </tr>
                                        )}

                                        {getShippingAmount() > 0 && (
                                            <tr>
                                                <td></td>
                                                <td className="name">
                                                    <Translation
                                                        id="b0bb9d47-0aca-4f28-9a5e-6c159a12fdb9"
                                                        defaultMessage="Shipping"
                                                        category="Label"
                                                    />
                                                    <ContextHelp context={"order-summary-shipping"} />
                                                </td>
                                                <td className="totalPrice">
                                                    <Money currency={getCurrency()} amount={getShippingAmount().toFixed(2)} />
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td></td>
                                            <td className="name">
                                                <Translation
                                                    id="ba14389f-2999-4067-9244-04d2fb5932c4"
                                                    defaultMessage="Total Amount"
                                                    category="Label"
                                                />
                                            </td>
                                            <td className="totalPrice">
                                                <Money currency={getCurrency()} amount={formatCurrency(getGrandTotalAmount())} />
                                            </td>
                                        </tr>

                                        {getPartialPaymentAmount() != getGrandTotalAmount() && (
                                            <tr className="color-orange">
                                                <td></td>
                                                <td className="name">
                                                    <Translation
                                                        id="c7c4541a-7b83-4ce2-9772-59076c363a5c"
                                                        defaultMessage="Payment Due Now"
                                                        category="Label"
                                                    />
                                                </td>
                                                <td className="totalPrice">
                                                    <Money
                                                        currency={getCurrency()}
                                                        amount={formatCurrency(getPartialPaymentAmount())}
                                                    />
                                                </td>
                                            </tr>
                                        )}

                                        {getFutureOverageAmount() > 0 && (
                                            <tr>
                                                <td></td>
                                                <td className="name">
                                                    <Translation
                                                        id="25552edc-3b23-4017-a4a0-008aaa18c5dc"
                                                        defaultMessage="Estimated Overage"
                                                        category="Label"
                                                    />
                                                </td>
                                                <td className="totalPrice">
                                                    <Money
                                                        currency={getCurrency()}
                                                        amount={formatCurrency(getFutureOverageAmount())}
                                                    />
                                                </td>
                                            </tr>
                                        )}

                                        {getPartialPaymentAmount() != getGrandTotalAmount() && !props.plantPickup && (
                                            <tr>
                                                <td></td>
                                                <td className="name">
                                                    <Translation
                                                        id="54d8d735-093c-4ac1-920b-81c4ee8b7a67"
                                                        defaultMessage="Estimated Shipping"
                                                        category="Label"
                                                    />
                                                </td>
                                                <td className="totalPrice">
                                                    {getFutureShippingAmount() > 0 ? (
                                                        <Money
                                                            currency={getCurrency()}
                                                            amount={formatCurrency(getFutureShippingAmount())}
                                                        />
                                                    ) : (
                                                        <span>TBD</span>
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                    </>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="agree-to-terms">
                    <label htmlFor="agreeId" className="controlContainer control checkbox agree-to-terms-align-right">
                        <input
                            type="checkbox"
                            data-testid="order-placement-review-terms-checkbox"
                            id="agreeId"
                            className="checkbox"
                            {...props.register("userAgreedToTerms", {
                                required: "You must agree to ePac's Terms and Conditions before submitting your order",
                                onChange: (e) => onCheckBoxChanged(e.target.value),
                            })}
                        />
                        <Translation
                            id="3474ccaa-e1da-488f-a1b8-1202c9983883"
                            defaultMessage="By placing this order I agree to:"
                            category="Label"
                        />{" "}
                        <a target="_blank" rel="noopener noreferrer" href={`${props.termsConditionUrl}`}>
                            <Translation
                                id="d6e8ba98-e88d-4358-a95a-e0a8620a2db5"
                                defaultMessage="ePac's Terms and Conditions"
                                category="Label"
                            />
                        </a>
                    </label>
                    {props.errors && props.errors.agreedToTerms && (
                        <span className="error-message agree-to-terms-align-right">
                            {props.errors.agreedToTerms.message}
                        </span>
                    )}
                </div>
            </Card>
        </div>
    ) : (
        ""
    );
};

/*
export const OrderTotalsDisplay = (props) => {
    const getCurrency = () => {
        return props.offer.supportedCountry.currency;
    };

    const getTotalSkuQuantity = () => {
        let qty = 0;
        if (props.skus) {
            props.skus.forEach((sku) => {
                qty += sku.quantity;
            });
        }
        return qty;
    };

    const getUnitPrice = (amount) => {
        return parseFloat(amount.toFixed(4));
    };

    const getSubTotalPrice = (unitPrice, quantity) => {
        let total = getUnitPrice(unitPrice) * quantity;
        return parseFloat(total.toFixed(2));
    };

    const getCalculationBreakdown = () => {
        let selectedCalc = null;
        let totalOrderQuantity = getTotalSkuQuantity();
        let calcs = props.offer.calculationBreakdown;
        calcs.forEach((calc) => {
            if (calc.quantityBreak <= totalOrderQuantity) {
                selectedCalc = calc;
            }
        });
        return selectedCalc;
    };

    const hasEpacConnect = () => {
        let calc = getCalculationBreakdown();
        return calc && calc.sellingPrice && calc.sellingPrice.epacConnect !== null;
    };

    const hasProductOverage = () => {
        let calc = getCalculationBreakdown();
        return calc && calc.sellingPrice && calc.sellingPrice.product !== null;
    };

    const hasShipping = () => {
        let calc = getCalculationBreakdown();

        return props.plantPickup == true ? false : calc && calc.sellingPrice && calc.sellingPrice.shipping !== null;
    };

    const getSubTotalAmount = () => {
        let result = 0;
        if (props.skus) {
            props.skus.forEach((sku) => {
                result += getSubTotalPrice(sku.unitPrice, sku.quantity);
            });
        }
        return result;
    };

    const getConnectAmount = () => {
        let amount = 0;
        if (hasEpacConnect()) {
            let calc = getCalculationBreakdown();
            amount = getSubTotalPrice(calc.sellingPrice.epacConnect.unitPrice.amount, getTotalSkuQuantity());
        }
        return amount;
    };

    const getOverageAmount = () => {
        let amount = 0;

        if (props.offer.isPressProof) {
            return 0;
        }

        var qty = getTotalSkuQuantity();
        var calc = getCalculationBreakdown();

        if (hasEpacConnect()) {
            let connectOverage = (qty / 100) * calc.sellingPrice.epacConnect.overagePercentage;
            connectOverage = connectOverage < 1 ? 1 : connectOverage;
            amount += getSubTotalPrice(calc.sellingPrice.epacConnect.unitPrice.amount, connectOverage);
        }

        if (hasProductOverage()) {
            let productOverage = (qty / 100) * calc.sellingPrice.product.overagePercentage;
            productOverage = productOverage < 1 ? 1 : productOverage;
            amount += getSubTotalPrice(calc.sellingPrice.product.unitPrice.amount, productOverage);
        }
        return amount;
    };

    const getShippingAmount = () => {
        let amount = 0;
        if (hasShipping()) {
            var calc = getCalculationBreakdown();
            let baseAmount = getSubTotalAmount();
            baseAmount += getConnectAmount();
            baseAmount += getOverageAmount();
            amount = (baseAmount / 100) * calc.sellingPrice.shipping.overagePercentage;
        }
        return amount;
    };

    const getGrandTotalAmount = () => {
        let amount = getSubTotalAmount();
        amount += getConnectAmount();
        amount += getOverageAmount();
        amount += getShippingAmount();
        return amount;
    };

    const onCheckBoxChanged = (value, valid) => {
        var grandTotalAmount = getGrandTotalAmount();
        let orderIntentTotals = {
            grandTotalAmount: getGrandTotalAmount(),
            shippingAmount: getShippingAmount(),
            overageAmount: getOverageAmount(),
            epacConnectAmount: getConnectAmount(),
            partialPaymentAmount: getPartialPaymentAmount(),
            estimatedShippingAmount: 0,
            estimatedOverageAmount: 0,
            subTotalAmount: getSubTotalAmount()
        };
        console.log("agreed", orderIntentTotals);
        if (props.onAgreed) {
            props.onAgreed(value, grandTotalAmount, orderIntentTotals);
        }
    };

    const formatCurrency = (value) => {
        return FormatProvider.FormatDecimalWithCommas(value.toFixed(2), 2);
    };

    const formatQuantity = (value) => {
        return FormatProvider.FormatNumberWithCommas(value.toFixed(2));
    };

    const getPartialPaymentAmount = () => {
        var totalAmount = getGrandTotalAmount();

        if (props.offer.salesForceOpportunity.hasOwnProperty("initialPaymentDuePercentage")) {
            return totalAmount * (props.offer.salesForceOpportunity.initialPaymentDuePercentage / 100);
        } else {
            switch (props.offer.salesForceOpportunity.paymentTerms) {
                case PaymentTerms.Net30_50_50:
                    return totalAmount / 2;
                default:
                    return totalAmount;
            }
        }
    };

    return props.skus ? (
        <div>
            <Card
                title={
                    <Translation
                        id="0e955704-3aa3-4975-b9f5-f4e958bacad3"
                        defaultMessage="SKU Description"
                        category="Card Title"
                    />
                }
            >
                <div className="skus display">
                    <table>
                        <thead>
                            <tr>
                                <th className="description"></th>
                                <th className="unitPrice">
                                    <Translation
                                        id="5e4f786d-029d-4464-8911-dcb3db5bab93"
                                        defaultMessage="Unit Price"
                                        category="Label"
                                    />
                                </th>
                                <th className="quantity">
                                    <Translation
                                        id="6c6df362-bf55-4248-8e0a-4af3eb5c99d4"
                                        defaultMessage="Quantity"
                                        category="Label"
                                    />
                                </th>
                                <th className="totalPrice">
                                    <Translation
                                        id="696e3261-7aa2-4c77-9596-13e791781a35"
                                        defaultMessage="Total Price"
                                        category="Label"
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.skus.map((sku, idx) => {
                                return (
                                    <tr key={`sku${idx}`}>
                                        <td className="description">{sku.description}</td>
                                        <td className="unitPrice">
                                            <Money currency={getCurrency()} amount={sku.unitPrice.toFixed(4)} />
                                        </td>
                                        <td className="quantity">{formatQuantity(sku.quantity)}</td>
                                        <td className="totalPrice">
                                            <Money
                                                currency={getCurrency()}
                                                amount={formatCurrency(getSubTotalPrice(sku.unitPrice, sku.quantity))}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Card>
            <Card transparent={true}>
                <div className="skus display grandTotal">
                    <table>
                        <tbody>
                            <tr>
                                <td></td>
                                <td className="name">
                                    <Translation
                                        id="78a3595a-01dc-45ba-977d-054295d18ed2"
                                        defaultMessage="Subtotal"
                                        category="Label"
                                    />
                                </td>
                                <td className="totalPrice">
                                    <Money currency={getCurrency()} amount={formatCurrency(getSubTotalAmount())} />
                                </td>
                            </tr>
                            {hasEpacConnect() && (
                                <tr>
                                    <td></td>
                                    <td className="name">
                                        <Translation
                                            id="4c2c9b2d-92d6-402c-8117-4812e417e6d7"
                                            defaultMessage="ePac Connect"
                                            category="Label"
                                        />
                                    </td>
                                    <td className="totalPrice">
                                        <Money currency={getCurrency()} amount={formatCurrency(getConnectAmount())} />
                                    </td>
                                </tr>
                            )}

                            <tr>
                                <td></td>
                                <td className="name">
                                    <Translation
                                        id="ede6f5db-ad17-4dfa-b60a-6c48e656f515"
                                        defaultMessage="Overage"
                                        category="Label"
                                    />{" "}
                                    <span style={{ display: "inline-block" }}>
                                        {" "}
                                        <ContextHelp context={"order-summary-overage"}>Overage</ContextHelp>
                                        {/* <IconHelp
                                            cms="order-summary-overage"
                                            offerKey={props.offerKey}
                                            orderPlacementApi={props.orderPlacementApi}
                                            id="Overage"
                                            smallIcon={true}
                                        >
                                            Overage
                                        </IconHelp> }
                                    </span>
                                </td>
                                <td className="totalPrice">
                                    <Money currency={getCurrency()} amount={formatCurrency(getOverageAmount())} />
                                </td>
                            </tr>

                            {!props.plantPickup && (
                                <tr>
                                    <td></td>
                                    <td className="name">
                                        <Translation
                                            id="b0bb9d47-0aca-4f28-9a5e-6c159a12fdb9"
                                            defaultMessage="Shipping"
                                            category="Label"
                                        />{" "}
                                        <span style={{ display: "inline-block" }}>
                                            {" "}
                                            <ContextHelp context={"order-summary-shipping"}>Shipping</ContextHelp>
                                            {/* <IconHelp
                                                cms="order-summary-shipping"
                                                offerKey={props.offerKey}
                                                orderPlacementApi={props.orderPlacementApi}
                                                id="Shipping"
                                                smallIcon={true}
                                            >
                                                Shipping
                                            </IconHelp> }
                                        </span>
                                    </td>
                                    <td className="totalPrice">
                                        {getShippingAmount() > 0 ? (
                                            <Money currency={getCurrency()} amount={getShippingAmount().toFixed(2)} />
                                        ) : (
                                            <span>TBD</span>
                                        )}
                                    </td>
                                </tr>
                            )}

                            <tr>
                                <td></td>
                                <td className="name">
                                    <Translation
                                        id="ba14389f-2999-4067-9244-04d2fb5932c4"
                                        defaultMessage="Total Amount"
                                        category="Label"
                                    />
                                </td>
                                <td className="totalPrice">
                                    <Money currency={getCurrency()} amount={formatCurrency(getGrandTotalAmount())} />
                                </td>
                            </tr>

                            {getPartialPaymentAmount() != getGrandTotalAmount() && (
                                <tr className="color-orange">
                                    <td></td>
                                    <td className="name">
                                        <Translation
                                            id="c7c4541a-7b83-4ce2-9772-59076c363a5c"
                                            defaultMessage="Payment Due Now"
                                            category="Label"
                                        />
                                    </td>
                                    <td className="totalPrice">
                                        <Money
                                            currency={getCurrency()}
                                            amount={formatCurrency(getPartialPaymentAmount())}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="pull-right">
                    <Checkbox
                        value={false}
                        onChange={(value, valid) => {
                            onCheckBoxChanged(value, valid);
                        }}
                    >
                        <Translation
                            id="3474ccaa-e1da-488f-a1b8-1202c9983883"
                            defaultMessage="By placing this order I agree to:"
                            category="Label"
                        />{" "}
                        <a target="_blank" rel="noreferrer" href={`${props.termsConditionUrl}`}>
                            <Translation
                                id="d6e8ba98-e88d-4358-a95a-e0a8620a2db5"
                                defaultMessage="ePac's Terms and Conditions"
                                category="Label"
                            />
                        </a>
                    </Checkbox>
                </div>
            </Card>
        </div>
    ) : (
        ""
    );
};
*/

export const TestRollOrderTotalsDisplay = (props) => {

    var paymentsDisabledOnRegion = props.paymentsDisabledOnRegion;

    const getCurrency = () => {
        return props.offer.supportedCountry.currency;
    };

    const getTotalSkuQuantity = () => {

        return 1;
    };

    const getUnitPrice = (amount) => {
        return parseFloat(amount.toFixed(4));
    };

    const getSkuSubTotaAmount = (unitPrice, quantity) => {
        let total = getUnitPrice(unitPrice) * quantity;
        return parseFloat(total.toFixed(2));
    };

    const getCalculationBreakdown = () => {
        return props.offer.calculationBreakdown[0];
        //let selectedCalc = null;
        //let totalOrderQuantity = getTotalSkuQuantity();
        //let calcs = props.offer.calculationBreakdown;

        //console.log("callSSSSSSSSS444:",calcs)
        //calcs.forEach((calc) => {
        //    if (calc.quantityBreak <= totalOrderQuantity) {
        //        selectedCalc = calc;
        //    }
        //});
        //return selectedCalc;
    };

    const getSubTotalAmount = () => {
        let result = 0;
        if (props.skus) {
            props.skus.forEach((sku) => {
                result += getSkuSubTotaAmount(sku.unitPrice, sku.quantity);
            });
        }
        return result;
    };

    const getConnectAmount = () => {
        var br = getCalculationBreakdown();
        var percentage = getSkuQuantiyToQuantityBreakPercentage();
        return br.sellingPrice.epacConnect.totalPriceIncludingOverage.amount * percentage;
    };

    const getOverageAmount = () => {
        return 0;
    };

    const getShippingAmount = () => {
        if (!props.plantPickup) {
            var br = getCalculationBreakdown();
            var percentage = getSkuQuantiyToQuantityBreakPercentage();
            return br.initiallyDue.shipping.totalPriceIncludingOverage.amount * percentage;
        } else {
            return 0;
        }
    };

    const getSkuQuantiyToQuantityBreakPercentage = () => {
        var br = getCalculationBreakdown();

        var bqty = br.quantityBreak;
        var sqty = getTotalSkuQuantity();

        return sqty / 1;
    };

    const getGrandTotalAmount = () => {
        var res = 0;
        res += getSubTotalAmount();
        res += getConnectAmount();
        res += getOverageAmount();
        res += getShippingAmount();
        return roundDown(res, 2);
    };

    const getPartialPaymentAmount = () => {
        var br = getCalculationBreakdown();
        var percentage = getSkuQuantiyToQuantityBreakPercentage();
        var grandTotalAmount = getGrandTotalAmount();
        var amountDue = props.plantPickup
            ? roundDown(
                (br.initiallyDue.paymentAmountDue.amount -
                    br.initiallyDue.shipping.totalPriceIncludingOverage.amount) *
                percentage,
                2
            )
            : roundDown(br.initiallyDue.paymentAmountDue.amount * percentage, 2);

        return Math.abs(grandTotalAmount - amountDue) < 1 ? grandTotalAmount : amountDue;
    };

    const getFutureOverageAmount = () => {
        var br = getCalculationBreakdown();
        var percentage = getSkuQuantiyToQuantityBreakPercentage();
        return roundDown(br.initiallyDue.futureProductOverageAmount.amount * percentage, 2);
    };

    const getFutureShippingAmount = () => {
        if (!props.plantPickup) {
            var br = getCalculationBreakdown();
            var percentage = getSkuQuantiyToQuantityBreakPercentage();
            return roundDown(br.initiallyDue.futureShippingAmount.amount * percentage, 2);
        } else {
            return 0;
        }
    };

    const roundDown = (value, decimals) => {
        return parseFloat(value.toFixed(decimals));
    };

    const onCheckBoxChanged = (value, valid) => {
        var grandTotalAmount = getPartialPaymentAmount();
        let orderIntentTotals = {
            grandTotalAmount: getGrandTotalAmount(),
            shippingAmount: getShippingAmount(),
            overageAmount: getOverageAmount(),
            epacConnectAmount: getConnectAmount(),
            partialPaymentAmount: getPartialPaymentAmount(),
            estimatedShippingAmount: getFutureOverageAmount(),
            estimatedOverageAmount: getFutureOverageAmount(),
            subTotalAmount: getSubTotalAmount()
        };
        console.log("agreed", orderIntentTotals);
        if (props.onAgreed) {
            props.onAgreed(value, grandTotalAmount, orderIntentTotals);
        }
    };

    const formatCurrency = (value) => {
        return FormatProvider.FormatDecimalWithCommas(value.toFixed(2), 2);
    };

    const formatQuantity = (value) => {
        return FormatProvider.FormatNumberWithCommas(value.toFixed(2));
    };

    const getPrice = () => {
        var p = props.offer.calculationBreakdown[0].price.totalPrice.amount;
        return p;
        //var br = getCalculationBreakdown();
        //return br.price.totalPrice.amount;

    }
    const getMaterialLength = () => props.offer.calculationBreakdown[0]?.filmLength?.value;
    const getLengthUOM = () => props.offer.calculationBreakdown[0]?.filmLength?.unit ? props.offer.calculationBreakdown[0]?.filmLength?.unit : " ft";


    return props.skus ? (
        <div>
            <Card
                title={
                    <Translation
                        id="0e955704-3aa3-4975-b9f5-f4e958bacad3"
                        defaultMessage="SKU Description"
                        category="Card Title"
                    />
                }
            >
                <div className="skus display">
                    <table>
                        <thead>
                            <tr>
                                <th className="description"></th>
                                <th className="materialLength">
                                    <Translation id='42664f74-d7f0-403f-9497-46d973a9c649' defaultMessage='Material Length' category='Label' />
                                </th>
                                <th className="quantity">
                                    <Translation
                                        id="6c6df362-bf55-4248-8e0a-4af3eb5c99d4"
                                        defaultMessage="Quantity"
                                        category="Label"
                                    />
                                </th>
                                <th className="totalPrice">
                                    <Translation
                                        id="1c121194-9a5c-4815-b707-43d16469423d"
                                        defaultMessage="Total Price"
                                        category="Label"
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.skus.map((sku, idx) => {
                                return (
                                    <tr key={`sku${idx}`}>
                                        <td className="description">Test Roll</td>
                                        <td className="materialLength">
                                            {FormatProvider.FormatNumberWithCommas(getMaterialLength())}
                                            {getLengthUOM()}
                                        </td>
                                        <td className="quantity">{formatQuantity(1)}</td>
                                        <td className="totalPrice">
                                            <Money
                                                currency={getCurrency()}
                                                amount={formatCurrency(
                                                    getPrice()
                                                )}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Card>
            <Card transparent={true}>
                <div className="skus display grandTotal">
                    {
                        paymentsDisabledOnRegion ?
                            <div className="payment-disabled-warning">
                                <i>
                                    <Translation id='c7e48e68-12b5-439d-b32b-f43be33bc605' defaultMessage='Overage, Shipping, and VAT are not included in the Subtotal. A Prepayment request will be sent after artwork upload' category='Label' />
                                </i>
                            </div>
                            :
                            <table>
                                <tbody>
                                    {getConnectAmount() > 0 && (
                                        <tr>
                                            <td></td>
                                            <td className="name">
                                                <Translation
                                                    id="4c2c9b2d-92d6-402c-8117-4812e417e6d7"
                                                    defaultMessage="ePac Connect"
                                                    category="Label"
                                                />
                                                <ContextHelp context={"order-summary-connect"} />
                                            </td>
                                            <td className="totalPrice">
                                                <Money currency={getCurrency()} amount={formatCurrency(getConnectAmount())} />
                                            </td>
                                        </tr>
                                    )}

                                    {(getSubTotalAmount() != getGrandTotalAmount()) && (
                                        <tr>
                                            <td></td>
                                            <td className="name">
                                                <Translation
                                                    id="8bb80a73-14d0-4014-96b8-f3c0a73135a5"
                                                    defaultMessage="Subtotal"
                                                    category="Label"
                                                />
                                            </td>
                                            <td className="totalPrice">
                                                <Money currency={getCurrency()} amount={formatCurrency(getSubTotalAmount())} />
                                            </td>
                                        </tr>
                                    )}

                                    {getShippingAmount() > 0 && (
                                        <tr>
                                            <td></td>
                                            <td className="name">
                                                <Translation
                                                    id="b0bb9d47-0aca-4f28-9a5e-6c159a12fdb9"
                                                    defaultMessage="Shipping"
                                                    category="Label"
                                                />
                                                <ContextHelp context={"order-summary-shipping"} />
                                            </td>
                                            <td className="totalPrice">
                                                <Money currency={getCurrency()} amount={getShippingAmount().toFixed(2)} />
                                            </td>
                                        </tr>
                                    )}

                                    <tr>
                                        <td></td>
                                        <td className="name">
                                            <Translation
                                                id="ba14389f-2999-4067-9244-04d2fb5932c4"
                                                defaultMessage="Total Amount"
                                                category="Label"
                                            />
                                        </td>
                                        <td className="totalPrice">
                                            <Money currency={getCurrency()} amount={formatCurrency(getGrandTotalAmount())} />
                                        </td>
                                    </tr>

                                    {getPartialPaymentAmount() != getGrandTotalAmount() && (
                                        <tr className="color-orange">
                                            <td></td>
                                            <td className="name">
                                                <Translation
                                                    id="c7c4541a-7b83-4ce2-9772-59076c363a5c"
                                                    defaultMessage="Payment Due Now"
                                                    category="Label"
                                                />
                                            </td>
                                            <td className="totalPrice">
                                                <Money
                                                    currency={getCurrency()}
                                                    amount={formatCurrency(getPartialPaymentAmount())}
                                                />
                                            </td>
                                        </tr>
                                    )}



                                    {getPartialPaymentAmount() != getGrandTotalAmount() && !props.plantPickup && (
                                        <tr>
                                            <td></td>
                                            <td className="name">
                                                <Translation
                                                    id="54d8d735-093c-4ac1-920b-81c4ee8b7a67"
                                                    defaultMessage="Estimated Shipping"
                                                    category="Label"
                                                />
                                            </td>
                                            <td className="totalPrice">
                                                {getFutureShippingAmount() > 0 ? (
                                                    <Money
                                                        currency={getCurrency()}
                                                        amount={formatCurrency(getFutureShippingAmount())}
                                                    />
                                                ) : (
                                                    <span>TBD</span>
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                    }
                </div>
                <div className="agree-to-terms">
                    <label htmlFor="agreeId" className="controlContainer control checkbox agree-to-terms-align-right">
                        <input
                            type="checkbox"
                            data-testid="order-placement-review-terms-checkbox"
                            id="agreeId"
                            className="checkbox"
                            {...props.register("userAgreedToTerms", {
                                required: "You must agree to ePac's Terms and Conditions before submitting your order",
                                onChange: (e) => onCheckBoxChanged(e.target.value),
                            })}
                        />
                        <Translation
                            id="3474ccaa-e1da-488f-a1b8-1202c9983883"
                            defaultMessage="By placing this order I agree to:"
                            category="Label"
                        />{" "}
                        <a target="_blank" rel="noopener noreferrer" href={`${props.termsConditionUrl}`}>
                            <Translation
                                id="d6e8ba98-e88d-4358-a95a-e0a8620a2db5"
                                defaultMessage="ePac's Terms and Conditions"
                                category="Label"
                            />
                        </a>
                    </label>
                    {props.errors && props.errors.agreedToTerms && (
                        <span className="error-message agree-to-terms-align-right">
                            {props.errors.agreedToTerms.message}
                        </span>
                    )}
                </div>
            </Card>
        </div>
    ) : (
        ""
    );
};
