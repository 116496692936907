import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiDataAccess } from "../../infrastructure/ApiDataAccess";
import { Card } from "../../core/Card";
import { TileContainer, ButtonTile } from "../../core/ImageTile";
import { SectionTitle } from "../../core/SectionTitle";
import { Row } from "../../core/Row";
import { Column } from "../../core/Column";
import { SectionLabel } from "../../core/SectionLabel";

import './CertificateDetailsPage.css';

export const CertificateDetailsPage = () => {
    const dataAccess = new ApiDataAccess("/api/estimates");
    const { globalOfferId } = useParams();
    const [certificates, setCertificates] = useState(null);
    const [offer, setOffer] = useState(null);

    useEffect(() => {
        const loadCertificates = async () => {
            var data = await dataAccess.get(`/offer/${globalOfferId}/certificates`);
            console.log(data);
            setCertificates(data.certificateDetails)
            setOffer(data.offer)
        };
        loadCertificates();
    }, []);


    const getFormattedDimensions = () => {
        return offer.dimensions.gusset > 0
            ? `${offer.dimensions.width.toFixed(3)} in x ${offer.dimensions.length.toFixed(3)} in x ${offer.dimensions.gusset.toFixed(3) } in`
            : `${offer.dimensions.width.toFixed(3)} in x ${offer.dimensions.length.toFixed(3) } in`;
    }

    const onClick = (doc) => {
        var url = doc.url;
        const a = document.createElement('a');
        a.href = url;
        a.download = url.split('/').pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }


    return (
        <div className='certificatePage'>
            {offer &&
                <div>
                    <h1>Order {offer.salesForceOpportunity.referenceNumber}</h1>

                    <Card title='Details' subtitle='These are your offer details'>
                        <Row>
                            <Column>
                                <SectionTitle>Converting Method</SectionTitle>
                                <SectionLabel>{offer.convertingMethod.value}</SectionLabel>
                            </Column>
                        </Row>

                        <Row>
                            <Column>
                                <SectionTitle>Structure Specification</SectionTitle>
                                <SectionLabel>SPEC-{offer.packagingMaterial.structureSpecId}</SectionLabel>
                            </Column>
                        </Row>

                        <Row>
                            <Column>
                                <SectionTitle>Dimensions</SectionTitle>
                                <SectionLabel>{getFormattedDimensions()}</SectionLabel>
                            </Column>
                        </Row>

                    </Card>

                    {certificates.map((details, idx) => {
                        return (
                            <Card key={`card${idx}`} title={`${details.certificate.certificationTypeName} (${details.certificate.name})`} subtitle={details.certificate.description}>
                                <TileContainer>
                                    {details.documents.map((doc, idx) => {
                                        return (
                                            <ButtonTile key={`tile${idx}`} text='Download' onClick={() => { onClick(doc) }}>
                                                <CertificateDocument document={doc} />
                                            </ButtonTile>
                                        )
                                    })}
                                </TileContainer>
                            </Card>
                        )
                    })}


                </div>
            }
        </div>
    )
}


export const CertificateDocument = ({ document }) => {

    return (
        <div className='certificateDocument'>
            <img src='/images/pdf_logo.png' alt='pdf' />
            <p>{document.name}</p>
        </div>
    )

}
