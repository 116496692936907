import { useController } from "react-hook-form";
import { ContextHelp } from '../../core/ContextHelp';

export const RequiredRegistrationTextbox = (props) => {

    const { field, fieldState } = useController(props);

    return (
        <div>
            <span>{props.label}
                <span className="registration-validation-error" >*</span>
                <span className="registration-info-icon">
                    {props.helpContext &&
                        <ContextHelp context={`Account Verification ${props.helpContext}`} />
                    }
                </span>
            </span>

            <input
                type="text"
                {...field}
                autoComplete="none"
                className={`control textbox ${fieldState.error && fieldState.error.message ? "error" : ""}`}
                placeholder={props.placeHolder}
                required={props.required}
                value={props.value}
            />
            {fieldState.error && <span className="registration-validation-error">{fieldState.error.message}</span>}
        </div>
    );
};



export const ContactRegistrationTextbox = (props) => {

    const { field, fieldState } = useController(props);

    return (
        <div>
            <input
                type="text"
                {...field}
                autoComplete="none"
                className={`control textbox ${fieldState.error && fieldState.error.message ? "error" : ""}`}
                placeholder={props.placeHolder}
                required={props.required}
                pattern={props.pattern ? props.pattern : null}
                value={props.value}
            />
            {fieldState.error && <span className="registration-validation-error">{fieldState.error.message}</span>}
        </div>
    );
};
