import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import { useAuthContext } from "../../infrastructure/AuthContext";

export const MyAccountRouter = ({ region }) => {
     
    const navigate = useNavigate();
    const { getLoggedUser } = useAuthContext();
    let isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const [errorMessage, setErrorMessage] = useState();


    // subscribe to callback to get the parameter passed to login component
    useEffect(() => {
        instance.handleRedirectPromise().then(authResult => {
            if (authResult && authResult.state)
                region = authResult.state;
        }).catch(err => {
            console.log(err);
        });
    }, []);


    useEffect(() => {
        route();
    }, [isAuthenticated, region]);


    const route = async () => {

        // if not authenticated go back to landing page
        if (!isAuthenticated)
            navigate('/myaccount');

        // if user is not found print an error on screen
        var user = await getLoggedUser();
        if (user == null) {
            setErrorMessage("user not found! please login using a valid user");
            return;
        }

        // if user is not linked to a SF account go to verification page
        if (!user.salesforceAccountId)
        {
            if (region) {
                navigate(`/verification`, {
                    state: {
                        culture: region
                    }
                })
            }
            else {
                console.log("missing region info, account validation is not possible");
                return;
            }
        }
        // in case is all ok go to my orders page
        else
            navigate('/myorders')         
    }

    return (
        <div>
            {errorMessage}
        </div>
    )
}