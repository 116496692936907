import { useEffect } from "react";
import { useAuthContext } from "../../infrastructure/AuthContext";

export const Login = ({ redirectUri, state }) => {

    const { openLoginPage } = useAuthContext();

    useEffect(() => {
        openLoginPage(redirectUri, state);
    }, []);
};