import React, { useEffect, useState, useContext, useRef, forwardRef, useImperativeHandle } from 'react';
import { useParams } from 'react-router';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { TileContainer, ButtonTile } from '../core/ImageTile';
import { Tabs, Tab } from '../core/Tabs';
import { TabContent, ActiveTab } from '../components/TabContent';
import { Page } from '../core/Page';
import { Popup } from '../core/Popup';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCheckoutContext } from '../infrastructure/CheckoutContext';
import { Language } from '../infrastructure/Languages';
import { Screen } from '../infrastructure/Screen';
import { PouchPreview, RenderModel } from '../components/ThreeDModel';
import { LengthMeasurement } from '../infrastructure/Measurements';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { useUserContext } from '../infrastructure/UserContext';
import { useTracking } from "react-tracking";
import { useCookies } from 'react-cookie';

import './Features.css';
import { Visitor } from '../infrastructure/ServerStateStorage';
import { GoogleAnalyticsContext } from '../infrastructure/GoogleAnalyticsContext';


export const FeaturesPage = () => {

    var timer = null;

    const { group } = useParams();
    const navigate = useNavigate();
    const language = useContext(Language);
    const { userRegion } = useUserContext();
    const popup = useRef(null);
    const hangHolesSection = useRef(null);
    const zippersSection = useRef(null);
    const tearNotchesSection = useRef(null);
    const roundedCornersSection = useRef(null);
    const doubleCutsSection = useRef(null);
    const gussetTypesSection = useRef(null);
    const sealWidthsSection = useRef(null);
    const ventsSection = useRef(null);
    const { trackEvent } = useTracking({ page: "FeaturesPage" });

    const { product, material, dimensions, convertingMethod, updateFeature, updateSkuCount, updateCheckoutFlag, feature } = useCheckoutContext();
    const [response, setResponse] = useState(null);
    const [features, setFeatures] = useState(null);

    const [valid, setValid] = useState(false);

    const [zippers, setZippers] = useState(null);
    const [hangHoles, setHangHoles] = useState(null);
    const [tearNotches, setTearNotches] = useState(null);
    const [vents, setVents] = useState(null);
    const [roundedCorners, setRoundedCorners] = useState(null);
    const [sealWidths, setSealWidths] = useState(null);
    const [doubleCuts, setDoubleCuts] = useState(null);
    const [qrCodeTypes, setQrCodeTypes] = useState(null);
    const [gussetTypes, setGussetTypes] = useState(null);
    const [dielineInstructions, setDielineInstructions] = useState(null);

    const [selections, setSelections] = useState(null);
    const [rules, setRules] = useState(null);
    const [message, setMessage] = useState(null);
    const [scrollToSection, setScrollToSection] = useState(null);

    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);

    const [cookies, setCookie] = useCookies(['visitorId']);
    const [visitorId, setVisitorId] = useState(null);
    const pouchPreview = useRef(null);
    const [hasFeatureChange, setFlagForChange] = useState(false);

    const triggers = {
        zippers: 17,
        hangHoles: 18,
        tearNotches: 19,
        vents: 25,
        roundedCorners: 30,
        sealWidths: 31,
        doubleCuts: 32,
        qrCodeTypes: 33
    };

    const targets = {
        none: 0,
        zippers: 1,
        hangHoles: 2,
        tearNotches: 3,
        vents: 4,
        sealWidths: 5,
        roundedCorners: 6,
        doubleCuts: 7,
        qrCodeTypes: 8,
        gussetTypes: 9
    }

    const featureLocations = {
        independent: 1,
        centeredTo: 2,
        offsetFromTop: 3
    }



    useEffect(() => {
        Screen.scrollToElement('layout');
        applyFilter();

        var id = cookies.visitorId;
        if (id) {
            setVisitorId(id);
        }
    }, []);



    const applyFilter = async () => {

        loader.show();
        var request = {
            convertingMethodId: convertingMethod.convertingMethodId,
            structureSpecIds: [material.id],
            productId: product.productId,
            dimensions: {
                width: dimensions.selectedDims.width,
                length: dimensions.selectedDims.length,
                gusset: dimensions.selectedDims.gusset,
                measureUnitId: dimensions.selectedDims.measureUnitId
            },
            featureLocationOffset: {
                hangHoleOffset: null,
                tearNotchOffset: null,
                ventOffset: null,
                zipperOffset: null,
                measureUnitId: dimensions.selectedDims.measureUnitId
            }
        };
        console.log('REQUEST', request);

        var dataAccess = new ApiDataAccess('/api/packagingfeatures');
        var responses = await dataAccess.post('/', request);
        var response = responses[0];
        console.log('RESPONSE', response);
        setResponse(response);

        var f = {
            doubleCuts: response.doubleCuts,
            hangHoles: response.hangHoles,
            qrCodeTypes: response.qrCodeTypes,
            roundedCorners: response.roundedCorners,
            sealWidths: response.sealWidths,
            tearNotches: response.tearNotches,
            vents: response.vents,
            zippers: response.zippers,
            gussetTypes: response.gussetTypes
        }

        var selections = feature ? feature : {
            doubleCuts: null,
            hangHoles: null,
            qrCodeTypes: null,
            roundedCorners: null,
            sealWidths: null,
            tearNotches: null,
            vents: null,
            zippers: null,
            gussetTypes: null
        }

        setZippers(f.zippers);
        setHangHoles(f.hangHoles);
        setTearNotches(f.tearNotches);
        setVents(f.vents);
        setRoundedCorners(f.roundedCorners);
        setSealWidths(f.sealWidths);
        setDoubleCuts(f.doubleCuts);
        setQrCodeTypes(f.qrCodeTypes);
        setGussetTypes(f.gussetTypes)

       // console.log('RULES', response.featureDependentRules);
        setRules(response.featureDependentRules);

       // console.log('SELECTIONS', selections);
        setSelections(selections);

       // console.log('FEATURES', f);
        setFeatures(f);

        applyRules(f, response.featureDependentRules, selections);
        loader.hide();

    }

    const applySelections = (selections) => {
        if (selections) {
            if (selections.hangHoles && hangHolesSection.current) {
                hangHolesSection.current.select(selections.hangHoles.id);
            }

            if (selections.zippers && zippersSection.current) {
                zippersSection.current.select(selections.zippers.id);
            }

            if (selections.tearNotches && tearNotchesSection.current) {
                tearNotchesSection.current.select(selections.tearNotches.id);
            }

            if (selections.roundedCorners && roundedCornersSection.current) {
                roundedCornersSection.current.select(selections.roundedCorners.id);
            }

            if (selections.doubleCuts && doubleCutsSection.current) {
                doubleCutsSection.current.select(selections.doubleCuts.id);
            }

            if (selections.sealWidths && sealWidthsSection.current) {
                sealWidthsSection.current.select(selections.sealWidths.id);
            }

            if (selections.gussetTypes && gussetTypesSection.current) {
                gussetTypesSection.current.select(selections.gussetTypes.id);
            }

            if (selections.vents && ventsSection.current) {
                ventsSection.current.select(selections.vents.id);
            }
        }
    }


    const onFeatureItemSelected = (section, item) => {
        console.log(`SELECTED ${section} with Id ${item.id}`);
        //console.log(item);

        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'order_shopping_4',        // order_shopping_4
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'selection': section,         //e.g. zipper
                'element': item.value,        // e.g. Press-To-Close
            },
            userId: visitorId
        });

        // Set Selection
        setFlagForChange(true);
        selections[section] = item;
        setSelections(selections);
        var result = applyRules(features, rules, selections);


        if (result == false) {
            switch (section) {
                case 'hangHoles':
                    if (selections && selections.hangHoles) {
                        pouchPreview.current.setHangHoleType(selections.hangHoles.id, selections.hangHoles.defaultLocation, selections.hangHoles.unitofMeasureId);
                    }
                    Screen.scrollToElement('zippers');
                    break;
                case 'zippers':
                    if (selections && selections.zippers) {
                        pouchPreview.current.setZipperType(selections.zippers.id, selections.zippers.defaultLocation, selections.zippers.unitofMeasureId);
                    }
                    Screen.scrollToElement('tearNotches');
                    break;
                case 'tearNotches':
                    if (selections && selections.tearNotches) {
                        pouchPreview.current.setTearNotch(selections.tearNotches.id, selections.tearNotches.defaultLocation, selections.tearNotches.unitofMeasureId);
                    }
                    Screen.scrollToElement('roundedCorners');
                    break;
                case 'roundedCorners':
                    if (selections && selections.roundedCorners) {
                        pouchPreview.current.setRoundedCorners(selections.roundedCorners.id);
                    }
                    Screen.scrollToElement('vents');
                    break;
                /*
                case 'doubleCuts':
                    Screen.scrollToElement('sealWidths');
                    break;
                */
                /*
                case 'sealWidths':
                    if (selections && selections.sealWidths) {
                        pouchPreview.current.setSealWidth(selections.sealWidths.id);
                    }
                    Screen.scrollToElement('vents');
                    break;
                */
                case 'vents':
                    if (selections && selections.vents) {
                        pouchPreview.current.setVentType(selections.vents.id, selections.vents.defaultLocation, selections.vents.unitofMeasureId);
                    }
                    break;
            }
        }
    }

    const applyRules = (features, rules, selections) => {
        var appliedRules = [];
        var result = false;
        var previousSelections = JSON.parse(JSON.stringify(selections));

        setZippers(features.zippers);
        setHangHoles(features.hangHoles);
        setTearNotches(features.tearNotches);
        setVents(features.vents);
        setRoundedCorners(features.roundedCorners);
        setSealWidths(features.sealWidths);
        setDoubleCuts(features.doubleCuts);
        setQrCodeTypes(features.qrCodeTypes);
        setGussetTypes(features.gussetTypes);


        evaluateRules(features, selections, rules, 'hangHoles', appliedRules);
        evaluateRules(features, selections, rules, 'zippers', appliedRules);
        evaluateRules(features, selections, rules, 'tearNotches', appliedRules);
        evaluateRules(features, selections, rules, 'roundedCorners', appliedRules);
        evaluateRules(features, selections, rules, 'doubleCuts', appliedRules);
        evaluateRules(features, selections, rules, 'sealWidths', appliedRules);
        evaluateRules(features, selections, rules, 'vents', appliedRules);
        evaluateRules(features, selections, rules, 'gussetTypes', appliedRules);

        setDielineInstructions(GetDielineInstructions(appliedRules));

        if (selectionChanged(previousSelections, selections, 'hangHoles')) {
            showInfo('hangHoles', language.translateLabel('76d0e5ad-2c16-4f74-8cbf-762ade817083', 'Due to your last selection the available Hang Hole styles have changed. Please review your selections.'));
            result = true;
        }

        if (selectionChanged(previousSelections, selections, 'zippers')) {
            showInfo('zippers', language.translateLabel('3e42d59c-8395-4c19-88c0-59b959fc198d', 'Due to your last selection the available Zipper styles have changed. Please review your selections.'));
            result = true;
        }

        if (selectionChanged(previousSelections, selections, 'tearNotches')) {
            showInfo('tearNotches', language.translateLabel('fbe3b7a8-1809-4909-adf2-eed6b7ff7c93', 'Due to your last selection the available Tear Notch styles have changed. Please review your selections.'));
            result = true;
        }

        if (selectionChanged(previousSelections, selections, 'roundedCorners')) {
            showInfo('roundedCorners', language.translateLabel('6b2f8e71-3dde-4bd8-a8a1-6f47eb4a36f5', 'Due to your last selection the available Rounded Corner styles have changed. Please review your selections.'));
            result = true;
        }

        /*
        if (selectionChanged(previousSelections, selections, 'doubleCuts')) {
            showInfo('doubleCuts', language.translateLabel('0c07321a-3693-4d64-acee-48532def48a6', 'Due to your last selection the available Double Cut styles have changed. Please review your selections.'));
            result = true;
        }
        */

        /*
        if (selectionChanged(previousSelections, selections, 'sealWidths')) {
            showInfo('sealWidths', language.translateLabel('8f3fbc9c-e8e2-41ec-b708-126218a0b0df', 'Due to your last selection the available Seal Width styles have changed. Please review your selections.'));
            result = true;
        }
        */

        if (selectionChanged(previousSelections, selections, 'vents')) {
            showInfo('vents', language.translateLabel('3a433758-b176-40ca-9645-ad9472a887db', 'Due to your last selection the available Valve styles have changed. Please review your selections.'));
            result = true;
        }


        //console.log('SELECTIONS', selections);
        applySelections(selections);

        setValid(isValid(selections));

        return result;
    }

    const GetDielineInstructions = (rules) => {
        var featuresWithDependencies = {};
        if (rules && rules.length > 0) {
            rules.forEach(rule => {
                var targets = rule.targets && rule.targets.length > 0 ? rule.targets : null;
                targets.forEach(target => {
                    if (target.locationDefinition) {
                        if (!featuresWithDependencies[target.locationDefinition.relatedFeatureId]) {
                            featuresWithDependencies[target.locationDefinition.relatedFeatureId] = [];
                        }
                        target.locationDefinition.targetType = target.id;
                        featuresWithDependencies[target.locationDefinition.relatedFeatureId].push(target.locationDefinition);
                    }
                });
            });
        }
        console.log('FEATURE-DEPENDENCIES', featuresWithDependencies);

        return {
            featureDependencies: GetFeatureDependencies(featuresWithDependencies),
            ignoreErrorCodes: getErrorCodesToIgnore(featuresWithDependencies)
        }
    }

    const GetFeatureDependencies = (featuresWithDependencies) => {
        var result = [];
        if (featuresWithDependencies) {
            Object.keys(featuresWithDependencies).map((key => {
                featuresWithDependencies[parseInt(key)].forEach(item => {
                    var parent = getFeatureCodeFromTargetType(item.relatedFeatureId);
                    var child = getFeatureCodeFromTargetType(item.targetType);

                    var location = null;
                    switch (item.locationId) {
                        case 1:
                            location = 'Independend';
                            break;
                        case 2:
                            location = 'Center';
                            break;
                        case 3:
                            location = 'Fixed Offset';
                            break;
                        default:
                            throw new Error(`Location Id ${item.location} is not defined!`)
                    }

                    var dependency = {
                        parent: parent,
                        child: child,
                        location: location,
                        offset: item.offset
                    };

                    result.push(dependency);
                });
            }));
        }
        return result;
    }

    const getErrorCodesToIgnore = (featuresWithDependencies) => {
        var result = [];
        if (featuresWithDependencies) {
            Object.keys(featuresWithDependencies).map((key => {
                featuresWithDependencies[parseInt(key)].forEach(item => {
                    var item1 = getFeatureCodeFromTargetType(item.relatedFeatureId);
                    var item2 = getFeatureCodeFromTargetType(item.targetType);
                    result.push(`ZF-${item1}-${item2}`);
                    result.push(`ZF-${item2}-${item1}`);
                });
            }));
        }
        return result;
    }

    const getFeatureCodeFromTargetType = (targetType) => {
        switch (targetType) {
            case targets.zippers:
                return 'ZP';
                break;
            case targets.hangHoles:
                return 'HH';
                break;
            case targets.tearNotches:
                return 'TN';
                break;
            case targets.vents:
                return 'VL';
                break;
            case targets.none:   // case location offset fixed
                return '';
                break;
            default:
                throw new Error(`Target Type ${targetType} is not defined!`)
        }
    }

    const showInfo = (section, info) => {
        setMessage(info);
        setScrollToSection(section);
        popup.current.show();
        clearTimeout(timer);
        timer = setTimeout(() => {
            popup.current.close();
        }, 5000);
    }

    const onModalClosed = () => {
        Screen.scrollToElement(scrollToSection);
    }


    const selectionIsInPool = (selection, items) => {
        var result = false;
        if (items && selection) {
            items.forEach(item => {
                if (item.id == selection.id) {
                    result = true;
                }
            });
        }
        return result;
    }


    const selectionChanged = (sel1, sel2, section) => {

        if (!sel1[section] && sel2[section]) {
            return true;
        }

        if (sel1[section] && !sel2[section]) {
            return true;
        }

        if (sel1[section] && sel2[section] && sel1[section].id !== sel2[section].id && !sel1[section].isDefault) {
            return true;
        }

        return false;
    }



    const evaluateRules = (features, selections, rules, section, appliedRules) => {
        var item = selections[section];
        if (item) {
            rules.forEach(rule => {
                if (rule.triggers.find(t => t.id == triggers[section] && t.values.find(v => v == item.id))) {
                    console.log('RULE FOUND', rule);
                    if (appliedRules && appliedRules.push)
                        appliedRules.push(rule);

                    rule.targets.forEach(target => {

                        switch (target.id) {
                            case targets.zippers:
                                console.log("Target Zippers", target.values);
                                var items = getFeatureItems(features, targets.zippers, target);
                                if (!selectionIsInPool(selections['zippers'], items)) {
                                    selections['zippers'] = null;
                                    setSelections(selections);
                                }
                                setZippers(items);
                                break;
                            case targets.hangHoles:
                                console.log("Target HangHoles", target.values);
                                setHangHoles(null);
                                var items = getFeatureItems(features, targets.hangHoles, target);
                                if (!selectionIsInPool(selections['hangHoles'], items)) {
                                    selections['hangHoles'] = null;
                                    setSelections(selections);
                                }
                                setHangHoles(items);
                                break;
                            case targets.tearNotches:
                                console.log("Target TearNotches", target.values);
                                var items = getFeatureItems(features, targets.tearNotches, target);
                                if (!selectionIsInPool(selections['tearNotches'], items)) {
                                    selections['tearNotches'] = null;
                                    setSelections(selections);
                                }
                                setTearNotches(items);
                                break;
                            case targets.vents:
                                console.log("Target Vents", target.values);
                                var items = getFeatureItems(features, targets.vents, target);
                                if (!selectionIsInPool(selections['vents'], items)) {
                                    selections['vents'] = null;
                                    setSelections(selections);
                                }
                                setVents(items);
                                break;
                            case targets.sealWidths:
                                console.log("Target SealWidths", target.values);
                                var items = getFeatureItems(features, targets.sealWidths, target);
                                if (!selectionIsInPool(selections['sealWidths'], items)) {
                                    selections['sealWidths'] = null;
                                    setSelections(selections);
                                }
                                setSealWidths(items);
                                break;
                            case targets.roundedCorners:
                                console.log("Target RoundedCorners", target.values);
                                var items = getFeatureItems(features, targets.roundedCorners, target);
                                if (!selectionIsInPool(selections['roundedCorners'], items)) {
                                    selections['roundedCorners'] = null;
                                    setSelections(selections);
                                }
                                setRoundedCorners(items);
                                break;
                            case targets.gussetTypes:
                                console.log("Target GussetTypes", target.values);
                                var items = getFeatureItems(features, targets.gussetTypes, target);
                                if (!selectionIsInPool(selections['gussetTypes'], items)) {
                                    selections['gussetTypes'] = null;
                                    setSelections(selections);
                                }
                                setGussetTypes(items);
                                break;
                            case targets.doubleCuts:
                                console.log("Target DoubleCuts", target.values);
                                var items = getFeatureItems(features, targets.doubleCuts, target);
                                if (!selectionIsInPool(selections['doubleCuts'], items)) {
                                    selections['doubleCuts'] = null;
                                    setSelections(selections);
                                }
                                setDoubleCuts(items);
                                break;
                            case targets.qrCodeTypes:
                                console.log("Target QrCodeTypes", target.values);
                                var items = getFeatureItems(features, targets.qrCodeTypes, target);
                                if (!selectionIsInPool(selections['qrCodeTypes'], items)) {
                                    selections['qrCodeTypes'] = null;
                                    setSelections(selections);
                                }
                                setQrCodeTypes(items);
                                break;
                        }
                    });
                }
            });
        }
    }

    const getFeatureItems = (features, targetType, target) => {
        var result = [];

        console.log('TARGET', target);

        var ids = target.values;
        var locationDependency = target.locationDefinition;

        var items = [];
        switch (targetType) {
            case targets.zippers:
                items = features.zippers;
                break;
            case targets.hangHoles:
                items = features.hangHoles;
                break;
            case targets.tearNotches:
                items = features.tearNotches;
                break;
            case targets.vents:
                items = features.vents;
                break;
            case targets.sealWidths:
                items = features.sealWidths;
                break;
            case targets.roundedCorners:
                items = features.roundedCorners;
                break;
            case targets.doubleCuts:
                items = features.doubleCuts;
                break;
            case targets.qrCodeTypes:
                items = features.qrCodeTypes;
                break;
            case targets.gussetTypes:
                items = features.gussetTypes;
                break;
        }

        items.forEach(item => {

            var found = false;
            switch (target.affinity) {
                case 'Exclusive':
                    found = ids.find(e => e == item.id)
                    break;
                case 'Include':
                    found = true;
                    break;
                case 'Exclude':
                    found = ids.find(e => e != item.id)
                    break;
            }

            if (found) {
                var location = item.defaultLocation;

                if (locationDependency) {

                    if (locationDependency.locationId == featureLocations.centeredTo && locationDependency.relatedFeatureId > 0) {
                        switch (locationDependency.relatedFeatureId) {
                            case targets.hangHoles:
                                var relatedItem = selections['hangHoles'];
                                location = relatedItem.defaultLocation - locationDependency.offset;
                                break;
                            case targets.zippers:
                                var relatedItem = selections['zippers'];
                                location = relatedItem.defaultLocation - locationDependency.offset;
                                break;
                            case targets.tearNotches:
                                var relatedItem = selections['tearNotches'];
                                location = relatedItem.defaultLocation - locationDependency.offset;
                                break;
                            case targets.vents:
                                var relatedItem = selections['vents'];
                                location = relatedItem.defaultLocation - locationDependency.offset;
                                break;
                            default:
                                console.log('Should not happen');
                                break;
                        }
                    }

                    if (locationDependency.locationId == featureLocations.offsetFromTop) {
                        location = locationDependency.offset;
                    }
                }

                var obj = {
                    id: item.id,
                    isDefault: item.isDefault,
                    value: item.value,
                    defaultLocation: location,
                    unitofMeasureId: item.unitofMeasureId,
                    isLocationRequired: item.isLocationRequired,
                }

                result.push(obj);
            }
        });

        console.log('FEATUREITEMS', result)


        return result;
    }


    const isValid = (selections) => {

        if (!selections) {
            return false;
        }

        /*
        if (!selections.doubleCuts) {
            return false;
        }
        */

        if (!selections.hangHoles) {
            return false;
        }

        /*
        if (!selections.qrCodeTypes) {
            return false;
        }
        */
        if (!selections.roundedCorners) {
            return false;
        }

        /*
        if (!selections.sealWidths) {
            return false;
        }
        */

        if (!selections.tearNotches) {
            return false;
        }

        if (!selections.vents) {
            return false;
        }

        if (!selections.zippers) {
            return false;
        }

        return true;
    }

    const onNextClick = () => {
        selections['doubleCuts'] = getDefaultFeature(doubleCuts);
        selections['qrCodeTypes'] = getDefaultFeature(qrCodeTypes);
        selections['sealWidths'] = getDefaultFeature(sealWidths);
        selections['gussetTypes'] = getDefaultFeature(gussetTypes);
        selections['dielineInstructions'] = dielineInstructions;

        var selectionValueList = convertFeaturesToString(selections);

        // reset future steps if change is made to features
        if (hasFeatureChange) {
            updateSkuCount(null);
            updateCheckoutFlag(false);
        }

        Object.keys(selections).forEach(key => {
            var assetProvider = new AssetProvider(key, language);
            selections[key].imageUrl = assetProvider.getImageUrl(selections[key]);
        })

        updateFeature(selections);

        trackEvent({
            event: 'ordering_tool_selections',
            eventProps: {
                'step': 'order_shopping_4',        // order_shopping_4
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'selection': selectionValueList        //e.g. round(06) | None | Standard | No | Default (1mm)
            },
            userId: visitorId
        });

        console.log('SELECTIONS', selections);

        setFlagForChange(false);
        navigate(`/${userRegion}/group/${group}/convertingMethod/${convertingMethod.convertingMethodId}/material/${material.id}/skus`);
    }

    const convertFeaturesToString = (selections) => {
        var selectionValueList = "";
        for (const property in selections) {
            if (property === 'doubleCuts') {
                selectionValueList += selections['doubleCuts'].description + "|";
            } else {
                selectionValueList += selections[property].value + "|";
            }
        }
        return selectionValueList;
    }

    const getDefaultFeature = features => {
        var result = null;
        if (features && features.length > 0) {
            result = features.find(e => e.isDefault);
            if (!result) {
                result = features[0];
            }
        }
        if (result && result.description) {
            var mappedObj = {
                id: result.id,
                value: result.description,
                uomId: result.uomId
            }
            return mappedObj;
        }
        return result;
    }
    const onBackButtonClicked = () => {
        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'order_shopping_4',        // order_shopping_1
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'element': 'back', // e.g. choose_this_style, information, photo_scroll, back
            },
            userId: visitorId
        });

        navigate(`/${userRegion}/group/${product.productGroupId}/convertingMethod/${convertingMethod.convertingMethodId}/dimensions/material`);
    }

    return (
        <div>

            <Popup className='selectionConflictMessage' closeOnClickAway={true} ref={popup} onClose={onModalClosed}>
                <p className='message'>{message}</p>
            </Popup>


            <Page className='features' onRenderHeader={() => {
                return (
                    <Tabs>
                        <Tab title={language.translateLabel('04ea9f38-1c48-4a34-9f44-ebbefbcc6436', 'Your Selections', 'Tab')}><TabContent activeTab={ActiveTab.Feature} /></Tab>
                    </Tabs>
                )
            }} >

                <PouchPreview ref={pouchPreview} request={{
                    ProductGroupType: RenderModel.MapProductGroupType(convertingMethod.convertingMethodId),
                    LengthInMillimeters: (new LengthMeasurement(dimensions.selectedDims.length, dimensions.selectedDims.measureUnitId)).convertToMillimeters(),
                    WidthInMillimeters: (new LengthMeasurement(dimensions.selectedDims.width, dimensions.selectedDims.measureUnitId)).convertToMillimeters(),
                    GussetInMillimeters: (new LengthMeasurement(dimensions.selectedDims.gusset, dimensions.selectedDims.measureUnitId)).convertToMillimeters(),
                    FinishType: RenderModel.MapFinishType(material.finishTypeId)
                }} />



                {hangHoles &&
                    <FeatureSection visitorId={visitorId} ref={hangHolesSection} value={selections.hangHoles} title={language.translateLabel('b5edc4ce-7749-4b12-b17b-ad49ffec15eb', 'Do you want a hang hole on your pouch?')} subTitle={language.translateLabel('5ee85f3a-c882-45ce-971a-a46108f86709', 'A hanghole is used to hang your product on the shelf peg. It comes at no additional cost.')} features={hangHoles} section='hangHoles' onSelected={onFeatureItemSelected} />
                }

                {zippers &&
                    <FeatureSection visitorId={visitorId} ref={zippersSection} value={selections.zippers} title={language.translateLabel('1ccc18d4-281a-420c-9b3f-51ee3fd5bede', 'Do you want a zipper?')} subTitle={language.translateLabel('30d6c288-a58d-4d38-a69f-d05ae05dc5e1', 'Zipper provides longer shelf life to your product. This will impact the cost of your pouch.')} features={zippers} section='zippers' onSelected={onFeatureItemSelected} />
                }

                {tearNotches &&
                    <FeatureSection visitorId={visitorId} ref={tearNotchesSection} value={selections.tearNotches} title={language.translateLabel('3441b4b9-e8dc-4f2c-b1d4-dfe745d8546f', 'Do you want a tear notch?')} subTitle={language.translateLabel('b6e9547e-c440-42d4-9e47-19faf1b92e4d', 'Tear notch makes it easier to open the package. It comes at no additional cost.')} features={tearNotches} section='tearNotches' onSelected={onFeatureItemSelected} />
                }

                {roundedCorners &&
                    <FeatureSection visitorId={visitorId} ref={roundedCornersSection} value={selections.roundedCorners} title={language.translateLabel('ea308b3e-a60c-44a0-9f6e-ae0f3b82c9ad', 'Do you want rounded corners?')} subTitle={language.translateLabel('0a2a20d5-d078-40a7-8d9a-049abd3a16e1', 'It comes at no additional cost.')} features={roundedCorners} section='roundedCorners' onSelected={onFeatureItemSelected} />
                }

                {/*
                {gussetTypes &&
                    <FeatureSection visitorId={visitorId} ref={gussetTypesSection} value={selections.gussetTypes} title={'Gusset Types'} subTitle={''} features={gussetTypes} section='gussetTypes' onSelected={onFeatureItemSelected} />
                }

                {doubleCuts &&
                    <FeatureSection ref={doubleCutsSection} value={selections.doubleCuts} title={language.translateLabel('83834077-aa87-4c55-98bc-da97ec6e721d', 'Do you want double cut?')} subTitle={language.translateLabel('8e8742c3-06eb-4614-90ce-40fdb9e14616', 'It comes at no additional cost.')} features={doubleCuts} section='doubleCuts' onSelected={onFeatureItemSelected} />
                }

                {sealWidths &&
                    <FeatureSection ref={sealWidthsSection} value={selections.sealWidths} title={language.translateLabel('464c7a85-5645-432f-9187-e490d3c28cba', 'What seal width do you want?')} subTitle={language.translateLabel('3fb4e530-1b3f-4ef6-af9b-780bae81316d', 'It comes at no additional cost.')} features={sealWidths} section='sealWidths' onSelected={onFeatureItemSelected} />
                }
                */}

                {vents &&
                    <FeatureSection visitorId={visitorId} ref={ventsSection} value={selections.vents} title={language.translateLabel('a47bd731-ad97-49cc-9749-69c0a0da0807', 'Do you want a valve?')} subTitle={language.translateLabel('9577e88d-1a29-4cf3-919b-eb5c5c5fa1f9', 'That costs something.')} features={vents} section='vents' onSelected={onFeatureItemSelected} />
                }


                {product && convertingMethod &&
                    <button className='btn btn-tertiary-outline back' onClick={() => { onBackButtonClicked() }}><span className='btn-text'>{language.translateLabel('48cda936-8f88-4703-a02c-69648eb3fa2a', 'Back', 'Button')}</span></button>
                }

                <button data-testid="feature-selection-button-next" className='btn btn-secondary pull-right next' disabled={!valid} onClick={onNextClick}><span className='btn-text'>{language.translateLabel('bd4339c0-1b67-459a-aaa7-6c4896f46ff7', 'Next', 'Button')}</span></button>

            </Page>
        </div>
        )
}


export const FeatureSection = forwardRef(({ title, subTitle, value, features, section, visitorId, onSelected }, ref) => {

    const language = useContext(Language);
    const { product } = useCheckoutContext();
    const googleAnalyticsContext = new GoogleAnalyticsContext('order_shopping_4', visitorId, product.industryName, product.industryCategoryName);
    const assetProvider = new AssetProvider(section, language);
    const [selected, setSelected] = useState(value ? value.id : -1);
    const onClicked = (item) => {
        if (onSelected) {
            onSelected(section, item);
        }
    }


    useImperativeHandle(ref, () => ({
        select(id) {
            setSelected(id);
        }
    }));


    return (
        <div data-testid={"feature-section-" + section } className='featureSection'>
            <h2 id={section}>{title}</h2>
            <p>{subTitle}</p>

            <TileContainer>
                {features.map((item, idx) => {
                    console.log(item);
                    return (
                        <ButtonTile className={selected == item.id ? 'feature active-border' : 'feature'} key={`tile${idx}`} height={340} onClick={() => { onClicked(item) }} helpContext={`Feature ${section} ${item.id}}`} gaContext={googleAnalyticsContext.CreateContext(`${section} - ${assetProvider.getTitle(item) }`)}>

                            <div data-testid={"feature-section-" + section + "-" + idx} className='imageContainer'>
                                <div className='picture' style={{ backgroundImage: `url("${assetProvider.getImageUrl(item) }")` }} />
                            </div>

                            <h3 className='text-align-center'>{assetProvider.getTitle(item)}</h3>

                        </ButtonTile>
                    )
                })}
            </TileContainer>

        </div>
        )
});


export class AssetProvider {
    constructor(section, language) {
        this.section = section;
        this.language = language;
    }

    getTitle(item) {
        var txt = item.description ? item.description : item.value;
        return this.language.translateDomain('a9266ed3-e0db-4b0e-81a5-b6b80c92e735', `${this.section}${item.id}`, txt, 'Feature Value');
    }

    getSectionName() {
        switch (this.section) {
            case 'hangHoles':
                return 'Hang Holes';
            case 'zippers':
                return 'Zippers';
            case 'tearNotches':
                return 'Tear Notches';
            case 'roundedCorners':
                return 'Rounded Corners';
            case 'doubleCuts':
                return 'Double Cuts';
            case 'sealWidths':
                return 'Seal Width';
            case 'gussetTypes':
                return 'Gusset Type';
            case 'vents':
                return 'Valves';
            case 'qrCodeTypes':
                return 'Connect';
            case 'dielineInstructions':
                return ''
            default:
                throw new Error(`Section ${this.section} is not supported!`);
        }
    }

    getImageUrl(item) {
        return this.language.imageUrl('2b85e067-f14b-43df-abf9-33792d334753', this.section + '_' + item.id, `Packaging Feature - ${this.getSectionName()}`, this.getTitle(item));
    }

}

export const Picture = ({ url, defaultUrl, className }) => {

    const language = useContext(Language);
    const [valid, setValid] = useState(false);

    const tester = new Image();
    tester.addEventListener('load', () => { setValid(true) });
    tester.addEventListener('error', () => { setValid(false) });
    tester.src = url;

    return (
        <div className={className ? `picture ${className}` : 'picture'} style={{ backgroundImage: `url("${!valid && defaultUrl ? defaultUrl : url}")` }} />
        )
}